/* global $ */
import * as utils from '../utils';
import initDisqus from './init';

export default class Disqus {
    constructor(options) {

        this.disqusShortname = options.disqus_shortname;
        this.showDisqus = options.show_disqus;
        this.pageUrl = options.page_url;
        this.pageId = options.page_id;
        this.addDisqus();
    }

    addDisqus = () => {
        if(!this.showDisqus) {
            return;
        }
        initDisqus(this.pageUrl, this.pageId, this.disqusShortname);
    }
}
