import './firstImpression';

const TRIGGER = {
    AFTER_LOAD_FIVE: 'afterLoadFive',
    AFTER_LOAD_TEN: 'afterLoadTen',
    ON_PAGE_SCROLL: 'onPageScroll',
    EXIT_INTENT_TRIGGER: 'exitIntentTrigger',
    AFTER_INACTIVITY_TEN: 'afterInactivityTen'
};

export default class Popup {
    constructor(options) {
        let componentId = options.componentId;
        let contentVersionId = options.contentVersionId;
        this._componentEl = options.componentEl;
        this._pageId = options.pageId;
        this._preset = options.preset;
        this._showAfterPeriod = options.showAfterPeriod;
        this._trigger = options.trigger;
        this._cookieName = `bento3.popup.${componentId}.${contentVersionId}`;
        const bodyId = 'page-' + this._pageId;

        this._elemCache = {
            closeBtn: this._componentEl.querySelector('.close-popup'),
            popup: this._componentEl.querySelector('.popup'),
            modal: this._componentEl.querySelector('.popup-modal'),
            popupOverlay: this._componentEl.querySelector('.popup-overlay'),
            body: document.getElementById(bodyId),
            stationHeader: document.querySelector('.station-header')
        };
        this.init();
    }

    init = () => {
        if (firstImpression(this._cookieName)) {
            firstImpression(this._cookieName, null);

            switch (this._trigger.value) {
                case TRIGGER.ON_PAGE_SCROLL:
                    document.addEventListener('scroll', this.displayPopup, true);
                    break;
                case TRIGGER.EXIT_INTENT_TRIGGER:
                    document.addEventListener('mouseout', (event) => {
                        //mouse out of document
                        if (event.toElement == null && event.relatedTarget == null) {
                            this.displayPopup();
                        }
                    });
                    window.addEventListener('blur', this.displayPopup); //shifting focus away from the current window
                    break;
                case TRIGGER.AFTER_INACTIVITY_TEN:
                    window.addEventListener('DOMContentLoaded', () => this.inactivityTime(600000)); //10 minutes = 1000 x 60 x 10
                    break;
                case TRIGGER.AFTER_LOAD_FIVE:
                    setTimeout(this.displayPopup, 5000);
                    break;
                case TRIGGER.AFTER_LOAD_TEN:
                    setTimeout(this.displayPopup, 10000);
                    break;
                default:
                    this.displayPopup();
            }
        }
    };

    displayPopup = () => {
        this._elemCache.popup.classList.add('open');
        window.addEventListener('resize', this.setBodyPadding);
        window.addEventListener('resize', this.moveStationHeader);

        let { closeBtn, popupOverlay } = this._elemCache;
        let allComponents = document.getElementsByClassName('component');
        for (let i = 0; i < allComponents.length; i++) {
            allComponents[i].setAttribute('aria-hidden', 'true');
        }
        this._elemCache.popup.setAttribute('aria-hidden', 'false');

        this.setTabIndex(document, '-1');
        this.setTabIndex(this._elemCache.popup, '0');

        this.setBodyPadding();
        this.moveStationHeader();

        if (closeBtn) {
            closeBtn.addEventListener('click', this.closePopup);
            closeBtn.focus();
        }
        if (popupOverlay) {
            popupOverlay.addEventListener('click', this.closePopup);
            document.addEventListener('keydown', (event) => {
                if(event.key === "Escape"){
                    this.closePopup();
                }
            });
        }

        //remove triggers
        switch (this._trigger.value) {
            case TRIGGER.ON_PAGE_SCROLL:
                document.removeEventListener('scroll', this.displayPopup);
                break;
            case TRIGGER.EXIT_INTENT_TRIGGER:
                document.removeEventListener('mouseout', this.displayPopup);
                window.removeEventListener('pagehide', this.displayPopup);
                break;
            case TRIGGER.AFTER_INACTIVITY_TEN:
                window.removeEventListener('DOMContentLoaded', this.inactivityTime);
                let events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
                events.forEach((eventName) => {
                    document.removeEventListener(eventName, this.displayPopup);
                });
                break;
        }
    };

    closePopup = () => {
        this._elemCache.popup.classList.add('closed');
        this._elemCache.popup.classList.remove('open');
        let allComponents = document.getElementsByClassName('component');
        for (let i = 0; i < allComponents.length; i++) {
            allComponents[i].setAttribute('aria-hidden', 'false');
        }
        this._elemCache.popup.setAttribute('aria-hidden', 'true');

        this.setTabIndex(document, '0');
        this.setTabIndex(this._elemCache.popup, '-1');
        this.resetBodyPadding();
        if (this._showAfterPeriod === 'True') {
            firstImpression(this._cookieName, 7); //set cookie with an expiration of 7 days
        } else {
            firstImpression(this._cookieName);
        }
    };

    setTabIndex = (element, tabIndex) => {
        let focusableElements = element.querySelectorAll(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );
        for (let i = 0; i < focusableElements.length; i++) {
            focusableElements[i].setAttribute('tabindex', tabIndex);
        }
    };

    setBodyPadding = () => {
        if (!this._elemCache.popup.classList.contains('closed')) {
            if (this._preset === 'fixedTop') {
                let modalHeight = this._elemCache.modal.offsetHeight + 'px';
                this._elemCache.body.style['padding-top'] = modalHeight;
                if (
                    this._elemCache.stationHeader &&
                    this._elemCache.stationHeader.classList.contains('navbar-fixed-top')
                ) {
                    this._elemCache.stationHeader.style['top'] = modalHeight;
                }
            }
            if (this._preset === 'fixedBottom') {
                let modalHeight = this._elemCache.modal.offsetHeight + 'px';
                this._elemCache.body.style['padding-bottom'] = modalHeight;
            }
        }
    };

    resetBodyPadding = () => {
        if (this._preset === 'fixedTop') {
            this._elemCache.body.style['padding-top'] = 0;
            if (
                this._elemCache.stationHeader &&
                this._elemCache.stationHeader.classList.contains('navbar-fixed-top')
            ) {
                this._elemCache.stationHeader.style['top'] = 0;
            }
        }
        if (this._preset === 'fixedBottom') {
            this._elemCache.body.style['padding-bottom'] = 0;
        }
    };

    moveStationHeader = () => {
        const observer = new MutationObserver(() => {
            if (this._elemCache.body.classList.contains('nav-is-open')) {
                this.resetBodyPadding();
            } else {
                setTimeout(() => {
                    this.setBodyPadding();
                }, 500);
            }
        });

        observer.observe(this._elemCache.body, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        });
    };

    inactivityTime = (t) => {
        let time;
        const that = this;
        let events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach((eventName) => document.addEventListener(eventName, resetTimer, true));

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(that.displayPopup, t);
        }
    };
}
