import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from '../../app/bento-components/video-list/video-cta.scss';
import { DEFAULT_CTA } from '../../app/bento-components/video-list/metadata';

class VideoCTA extends Component {
    static propTypes = {
        videoId: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string
        }).isRequired
    }

    render() {
        const { cta: {text, url, target}, videoId, isPillButtonAvailable } = this.props;
        const linkText = text || DEFAULT_CTA.text || DEFAULT_CTA.textPlaceholder;
        const linkUrl = url || DEFAULT_CTA.link.url;
        const linkTarget = target || DEFAULT_CTA.link.target;
        if(!url || url === '#'){
            return null;
        }

        if(!videoId) {
            return <div className={`video-cta shape-placeholder ${styles.videoCTA}`} />;
        }

			let pillButtonComponent = isPillButtonAvailable ? 'pill-button-component' : '';

			return (
            <a className={`video-cta ${styles.videoCTA} ${pillButtonComponent}`} href={linkUrl} target={linkTarget}>
                {linkText}
            </a>
        );
    }
}

export default VideoCTA;
