import icon from './media/icon.svg';
import disabledIcon from './media/disabledIcon.svg';
import { ALL_SITE_TYPES, sectionType, DOCS_BASE_URL } from 'constants';
import THEME from './theme.jsx';

const NAME = 'blog-river'; /* component internal name */
const VERBOSE_NAME = 'blog river'; /* component displayed name */

export const BENTOMATIC_FIELDS = {
    title: 'data.title'
};

export const PAGINATION_TYPES = {
    LOAD_MORE: 'LOAD_MORE',
    PAGINATION: 'PAGINATION'
};

export const ORDER = {
    // - for descending results the most recent first
    LAST_PUBLISHED: '-publication_date',
    LAST_UPDATED: '-update_date'
};

export const ALL_CATEGORIES = {
    id: 'ALL_CATEGORIES',
    title: 'All Categories'
};

export const ALL_TAGS = {
    id: 'ALL_TAGS',
    title: 'All Tags'
};

export const ITEMS_PER_PAGE = {
    min: 3,
    max: 24
};

export const FIRST_PAGE = 1;

/* component visible data */
const DATA = {
    title: '',
    titlePlaceholder: 'Blog River Title',
    callToActionText: '',
    callToActionTextPlaceholder: 'Read more'
};

/* component settings */
const SETTINGS = {
    categories: [ALL_CATEGORIES],
    tags: [ALL_TAGS],
    itemsPerPage: 15,
    paginationType: PAGINATION_TYPES.PAGINATION,
    order: ORDER.LAST_PUBLISHED,
    showBlogRiverTitle: true,
    showImage: true,
    showAuthor: true,
    showPostDate: true,
    showCallToAction: true,
    showSocialSharing: true,
    showFacebook: true,
    showTwitter: true,
    showInstagram: true,
    showEmail: true,
    featuredEntries: [],
    isPreview: false
};

const RESTRICTION = {
    allowedSections: new Set([sectionType.CONTENT]),
    allowedColumns: new Set([6, 8, 12]),
    allowedSiteTypes: ALL_SITE_TYPES
};

const METADATA = {
    name: NAME,
    verboseName: VERBOSE_NAME,
    icon,
    disabledIcon,
    data: DATA,
    settings: SETTINGS,
    restriction: RESTRICTION,
    theme: THEME
};

export const DOCUMENTATION_LINK = DOCS_BASE_URL + 'B3/4751588/Blog+River\n';

export default METADATA;
