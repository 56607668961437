import React from 'react';
import ReactDOM from 'react-dom';

import VideoListHOCFE from './video-list-hoc-fe';
import { rstrip } from '../utils';

class VideoListFE {
    constructor(options) {
        this.data = options.data;
        this.settings = options.settings;
        this.component = options.component;
        this.componentId = options.componentId;
        this.site = options.site;
        this.columnSize = options.columnSize;
        this.callsign = options.callsign;

        const apiBaseURL = rstrip(options.APIBaseURL, '/');
        this.API = {
            MEDIA_MANAGER_ASSETS: `${apiBaseURL}/bento-api/media-manager/${this.site.id}/assets/`,
        };
        this.basePath = options.basePath;

        this.init = this.init.bind(this);
        this.init();
    }

    init = () => {
        return ReactDOM.render(
            <VideoListHOCFE data={this.data}
                            settings={this.settings}
                            API={this.API}
                            site={this.site}
                            columnSize={this.columnSize}
                            basePath={this.basePath}
                            id={this.componentId}
                            callsign={this.callsign}
                            />,
            this.component
        );
    }
}

export default VideoListFE;
