/* global $ */
import moment from 'moment';
import 'jquery-ui';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker';
import * as utils from './utils';


export default function GoogleEventCalendar(calendarData) {
    this.events = {};
    this.currentMonth = null;
    this.currentYear = null;

    this.buildHtmlEvent = function(event, componentId){
        var formatedStartDate = event.StartDate.format('MM/DD/YYYY HH:mm') || '';
        var formatedEndDate = event.EndDate.format('MM/DD/YYYY HH:mm') || '';
        var el = $('<div />').attr({'class': 'event-wrapper event_' + componentId + '_' + event.ShortStartDate});
        $('<span class="bullet"></span>').appendTo(el);
        $('<span class="date" />').text(formatedStartDate + ' - ' + formatedEndDate + ': ').appendTo(el);
        $('<span class="title" />').text(event.Summary).appendTo(el);
        var eventLink = $("<a href='" + event.Link + "' rel='noopener noreferrer' target='_blank'>View Event</a>");
        eventLink.appendTo($('<div class="link"/>').appendTo(el));
        $('<hr />').appendTo(el);
        return el;
    };

    this.makeEvents = function(jsonItems, startOfMonth){
        var currentMonthYear = `${this.currentYear.toString()}_${this.currentMonth.toString()}`;
        var currentMonthEvents = [];
        var startOfMonthParsed = startOfMonth.split('T')[0];

        $.each(jsonItems, function(i, eventData){
            eventData = jsonItems[i];
            if (!eventData.start || !eventData.end){
                return;
            }
            var startDate = eventData.start.dateTime || eventData.start.date;
            var endDate = eventData.end.dateTime || eventData.end.date;
            currentMonthEvents[i] = {
                Summary:        eventData.summary || '',
                StartDate:      moment(startDate, 'YYYY-MM-DDTHH:mm:ssZ'),
                EndDate:        moment(endDate, 'YYYY-MM-DDTHH:mm:ssZ'),
                ShortStartDate: moment(startDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') || '',
                Description:    eventData.description || '',
                Link:           eventData.htmlLink || '',
                Color:          utils.getEventColor() || ''
            };
        });

       for (var i = currentMonthEvents.length; i--;) {
            if(startOfMonthParsed > currentMonthEvents[i].ShortStartDate) {
                currentMonthEvents.splice(i, 1);
            }
        }

        this.events[currentMonthYear] = currentMonthEvents;
    };

    this.getMonthEvents = function() {
        var currentMonthYear = `${this.currentYear.toString()}_${this.currentMonth.toString()}`;
        return this.events[currentMonthYear];
    };

    this.makeCalendarView = function(){
        var self = this;
        var currentMonthEvents = this.getMonthEvents();
        var calendarBox = $('#' + calendarData.componentId);
        $.each(currentMonthEvents, function(i, eventData){
            self.buildHtmlEvent(eventData, calendarData.componentId).appendTo(calendarBox.find('.events'));
        });

        calendarBox.children('.calendar').datepicker({
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            beforeShowDay: function(date) {
                var currentMonthEvents = self.getMonthEvents() || [];
                for(var i = 0; i < currentMonthEvents.length; i++) {
                    if(utils.isSameDay(currentMonthEvents[i].StartDate, date)){
                        utils.setEventColors(calendarData.componentId, currentMonthEvents[i].ShortStartDate);
                        return [
                            false,
                            'highlight_' + calendarData.componentId + '_' + currentMonthEvents[i].ShortStartDate,
                            null
                        ];
                    }
                }
                return [false, '', null];
            },
            onChangeMonthYear: function(year, month){
                self.currentMonth = month - 1;
                self.currentYear = year;
                var _calendar = $(this);
                calendarBox.find('.events').html('');
                utils.resetEventColors(calendarData.componentId);
                $('#' + calendarData.componentId + ' .pagination').hide();

                self.eventsByMonth(function(){
                    var newMonthEvents = self.getMonthEvents() || [];
                    _calendar.datepicker('refresh');
                    $.each(newMonthEvents, function(i, eventData){
                        self.buildHtmlEvent(eventData, calendarData.componentId).appendTo(calendarBox.find('.events'));
                    });
                    if(newMonthEvents.length) {
                        newMonthEvents.length > 2 && utils.paginateEvents(calendarBox);
                    }
                    else {
                        $('#' + calendarData.componentId + ' .pagination').hide();
                    }
                });

            }
        });
        if(currentMonthEvents && currentMonthEvents.length) {
            currentMonthEvents.length > 2 && utils.paginateEvents(calendarBox);
        }

    };

    this.eventsByMonth = function(callback){
        var self = this;
        var currentMonthEvents = this.getMonthEvents();
        if(!currentMonthEvents) {
            var startOfMonth = moment([self.currentYear, self.currentMonth]).startOf('month').format('YYYY-MM-DDTHH:mm:ssZ');
            var endOfMonth =  moment([self.currentYear, self.currentMonth]).endOf('month').format('YYYY-MM-DDTHH:mm:ssZ');
            var encodedCalendarId = encodeURIComponent(calendarData.calendarId);
            $.ajax({
                url: 'https://www.googleapis.com/calendar/v3/calendars/' + encodedCalendarId + '/events',
                data: {
                    'key': calendarData.googleDevKey,
                    'timeMin': startOfMonth, //minimum end time of event
                    'timeMax': endOfMonth,  //maximum start time of event
                    'singleEvents': 'true',
                    'orderBy': 'startTime'
                },
                dataType: 'json',
                success: function(data){
                    self.makeEvents(data.items || [], startOfMonth);
                    callback();
                }
            });
        }
        else {
            callback();
        }
    };

    this.buildCalendar = function() {
        var self = this;
        this.currentMonth = moment().month();
        this.currentYear = moment().year();
        this.eventsByMonth(function(){
            self.makeCalendarView();
        });
    };

    this.buildCalendar();
}

