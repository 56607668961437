/*global $, PBS */
import React from 'react';
import ReactDOM from 'react-dom';
import RSS from './rss';

const RSS_PROXY = 'bento-api/rss/?';

function logError(message) {
    if (typeof console === 'undefined') {
        return;
    }
    /* TODO: add raven.js on FE for loging sentry errors */
    console.error(message);
}

export default class RSSFeed {
    constructor(options) {
        this.itemsPerPage = parseInt(options.itemsPerPage, 10);
        this.title = options.title;
        this.showFeedTitle = options.showFeedTitle;
        this.showFeedDescription = options.showFeedDescription;
        this.showImage = options.showImage;
        this.showTitle = options.showTitle;
        this.showDescription = options.showDescription;
        this.showCTA = options.showCTA;
        this.showPubDate = options.showPubDate;
        this.component = options.component;
        this.channel = {};
        this.currentPage = 0;
				this.isComponentPreview = options.isComponentPreview;
        this.componentID = options.componentID;
        this.contentversionID = options.contentversionID;
        if (this.componentID && this.contentversionID) {
            this.init();
        }
    }

    init() {
        this.getFeed(this.contentversionID, this.componentID)
            .done((response) => {
                const { feed, items, description } = response;
                try {
                    if (!items || items.length === 0) {
                        const { error } = response;
                        this.renderErrorMessage(error);
                        logError('RSSFeed :: RSS Feed did not return any entries');
                        return;
                    }
                    this.channel = {
                        ...feed,
                        description: description,
                        items: items
                    };
                } catch (e) {
                    this.renderErrorMessage();
                    logError('RSSFeed :: Unsupported response');
                    return;
                }
                this.renderFeed();
            })
            .fail((xhr, status, error) => {
                this.renderErrorMessage(error);
                logError(`${status} ${error}`);
            });
    }

    getFeed(contentID, componentID) {
        let api_base_url = PBS.BentoConfig.APIBaseURL || '/';
        return $.ajax({
            url: `${api_base_url}${RSS_PROXY}content_id=${contentID}&component_id=${componentID}`,
            xhrFields: { withCredentials: true }
        });
    }

    renderFeed() {
        ReactDOM.render(
            <RSS
                currentPage={this.currentPage}
                channel={this.channel}
                title={this.title}
                itemsPerPage={this.itemsPerPage}
                isComponentPreview={this.isComponentPreview}
                showFeedTitle={this.showFeedTitle}
                showFeedDescription={this.showFeedDescription}
                showImage={this.showImage}
                showTitle={this.showTitle}
                showDescription={this.showDescription}
                showCTA={this.showCTA}
                showPubDate={this.showPubDate}
            />,
            this.component
        );
    }

    renderErrorMessage(responseError = '') {
        const defaultError =
            'Feed unavailable right now, please try refreshing the page or revisiting later';
        const finalError =
            typeof responseError === 'string' && responseError.length
                ? responseError
                : defaultError;
        ReactDOM.render(
            <div className="rss-feed">
                <div className="rss-error">{finalError}</div>
            </div>,
            this.component
        );
    }
}
