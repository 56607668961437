import { colorLuminance } from 'utils';

export default class SimpleButton {

    constructor(options) {
        this._component = options.component;
        this._colorPalette = options.colorPalette;
        this._settings = options.settings;
        this._postersPerPage = options.postersPerPage;
        this._elemCache = {
            simpleButtonLink: this._component.querySelector('.simple-button-link'),
            simpleButtonWrapper: this._component.querySelector('.simple-button-wrapper'),
            textInput: this._component.querySelector('.text-input')
        };

        const mainPaletteColor =  (this._colorPalette && this._colorPalette.main) || '#000';
        this.color = this._settings.color || mainPaletteColor;

        let changeColorsFct, resetColorsFct;

        if(options.settings && options.settings.style) {
            if(options.settings.style === 'filled') {
                changeColorsFct = this.changeFilledColors;
                resetColorsFct = this.resetFilledColors;
            }
            else {
                changeColorsFct = this.changeGhostColors;
                resetColorsFct = this.resetGhostColors;
            }
            this.init(changeColorsFct, resetColorsFct);
        }
    }

    init = (changeColorsFct, resetColorsFct) => {
        const { simpleButtonWrapper, simpleButtonLink, textInput } = this._elemCache;
        resetColorsFct();
        if(this._settings.showHoverEffect && textInput){
            if(simpleButtonWrapper){
                simpleButtonWrapper.addEventListener('mouseover', changeColorsFct);
                simpleButtonWrapper.addEventListener('mouseout', resetColorsFct);
            }
            if(simpleButtonLink){
                simpleButtonLink.addEventListener('focus',changeColorsFct);
                simpleButtonLink.addEventListener('blur', resetColorsFct);
            }
        }
    };

    changeFilledColors = () => {
        const { textInput } = this._elemCache;
        textInput.style.backgroundColor = colorLuminance(this.color, -0.2);
        textInput.style.borderColor = colorLuminance(this.color, -0.2);
    };

    resetFilledColors = () => {
        const { textInput } = this._elemCache;
        textInput.style.backgroundColor = this.color;
        textInput.style.borderColor = this.color;
    };

    changeGhostColors = () => {
        const { textInput } = this._elemCache;
        textInput.style.backgroundColor = this.color;
        textInput.style.borderColor = this.color;
        textInput.style.color = '#fff';
    };

    resetGhostColors = () => {
        const { textInput } = this._elemCache;
        textInput.style.backgroundColor = 'transparent';
        textInput.style.borderColor = this.color;
        textInput.style.color = this.color;
    };
}
