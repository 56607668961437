/* global $ */

export default class Gallery {
    constructor(options) {
        this._cache = {};
        this._component = options.component;
        this.id = options.id;
        this.init();
    }

    init = () => {
        this._setupCache();
        this._handleDisplay();
        this._addEvents();
    };

    /**
     * Caches re-used elements.
     */
    _setupCache = () => {
        this._cache.body = document.querySelector('body');
        this._cache.component = document.getElementById(this.id);
        this._cache.leftArrow = this._cache.component.querySelector('.arrow-left');
        this._cache.rightArrow = this._cache.component.querySelector('.arrow-right');

        this._cache.images = this._cache.component.querySelectorAll('.gallery-image');
        this._cache.thumbnails = this._cache.component.querySelectorAll('.thumbnail-item');
        this._cache.currentIndex = parseInt(window.location.search.slice(3), 10) || 0;
        this._cache.thumbnailsContainer = this._cache.component.querySelector(
            '.thumbnails-container'
        );
        this._cache.thumbnailList = this._cache.component.querySelector('.thumbnail-list');
        this._cache.thumbnailArrows = this._cache.component.querySelectorAll('.thumb-arrow');

        this._cache.fullscreenButtons = this._cache.component.querySelectorAll(
            '.fullscreen-button'
        );
        this._cache.fullscreenIcons = this._cache.component.querySelectorAll('.fullscreen-icon');
        this._cache.socialShareButtons = this._cache.component.querySelectorAll(
            '.social-share-button'
        );
        this._cache.socialShareContainers = this._cache.component.querySelectorAll(
            '.social-share-container'
        );
        this._cache.socialLinks = this._cache.component.querySelectorAll('.social-link');
    };

    /**
     * Navigates through the gallery, taking image index as a parameter.
     */
    _navigateToImage = (index) => {
        const prevImage = document.getElementById(
            'image-' + this.id + '-' + this._cache.currentIndex
        );
        prevImage.classList.remove('visible');

        // must check thumbnail list length in case user has thumbnails turned off
        if (this._cache.thumbnails.length > 0) {
            const prevThumbnail = document.getElementById(
                'thumbnail-' + this.id + '-' + this._cache.currentIndex
            );
            prevThumbnail.classList.remove('selected');
        }

        this._cache.currentIndex = index;
        const newImage = document.getElementById(
            'image-' + this.id + '-' + this._cache.currentIndex
        );
        newImage.className += ' visible';

        // must check thumbnail list length in case user has thumbnails turned off
        if (this._cache.thumbnails.length > 0) {
            const newThumbnail = document.getElementById(
                'thumbnail-' + this.id + '-' + this._cache.currentIndex
            );
            newThumbnail.className += ' selected';
        }

        if (this._cache.thumbnails.length) {
            const thItemWidth = this._cache.thumbnails[0].offsetWidth;
            this._cache.thumbnailsContainer.scrollLeft = index * thItemWidth;
        }
    };

    /**
     * Handles a click to the left gallery arrow.
     */
    _onLeftArrowClick = () => {
        const finalImageIndex = this._cache.images.length - 1;
        const currentIndex = parseInt(this._cache.currentIndex, 10);
        const prevImageIndex = currentIndex === 0 ? finalImageIndex : currentIndex - 1;
        this._navigateToImage(prevImageIndex);
    };

    /**
     * Handles a click to the right gallery arrow.
     */
    _onRightArrowClick = () => {
        const finalImageIndex = this._cache.images.length - 1;
        const currentIndex = parseInt(this._cache.currentIndex, 10);

        const nextImageIndex = currentIndex === finalImageIndex ? 0 : currentIndex + 1;
        this._navigateToImage(nextImageIndex);
    };

    /**
     * Handles a click to an image thumbnail.
     */
    _onThumbnailClick = (e) => {
        const clickedIndex = e.currentTarget.getAttribute('data-index');

        const prevImage = document.getElementById(
            'image-' + this.id + '-' + this._cache.currentIndex
        );
        const prevThumbnail = document.getElementById(
            'thumbnail-' + this.id + '-' + this._cache.currentIndex
        );
        prevImage.classList.remove('visible');
        prevThumbnail.classList.remove('selected');

        this._cache.currentIndex = clickedIndex;
        const newImage = document.getElementById(
            'image-' + this.id + '-' + this._cache.currentIndex
        );
        const newThumbnail = document.getElementById(
            'thumbnail-' + this.id + '-' + this._cache.currentIndex
        );
        newImage.className += ' visible';
        newThumbnail.className += ' selected';
    };

    /**
     * Handles a click to a thumbnail scrolling arrow.
     */
    _onThumbnailArrowClick = (e) => {
        const $thumbnailsContainer = $(this._cache.thumbnailsContainer);
        const direction = e.currentTarget.getAttribute('data-direction');
        const directionSign = direction === 'right' ? 1 : -1;
        const scrollAmount = Array.from(this._cache.thumbnails)[0].scrollWidth + 30;
        const currentPosition = this._cache.thumbnailsContainer.scrollLeft;
        const newPosition = currentPosition + directionSign * scrollAmount;

        // scrolls thumbnail list left or right
        $thumbnailsContainer.animate({ scrollLeft: newPosition }, 'fast');
    };

    /**
     * Enters fullscreen mode. If/else logic needed for cross-browser compatibility.
     */
    _enterFullscreen = () => {
        if (this._cache.component.requestFullscreen) {
            this._cache.component.requestFullscreen();
        } else if (this._cache.component.webkitRequestFullscreen) {
            this._cache.component.webkitRequestFullscreen();
        } else if (this._cache.component.mozRequestFullScreen) {
            this._cache.component.mozRequestFullScreen();
        } else if (this._cache.component.msRequestFullscreen) {
            this._cache.component.msRequestFullscreen();
        }
    };

    /**
     * Exits out of fullscreen mode. If/else logic needed for cross-browser compatibility.
     */
    _exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    /**
     * Handles a click to the fullscreen button.
     */
    _onFullscreenClick = (e) => {
        if (this._cache.component.classList.contains('fullscreen')) {
            this._exitFullscreen();
            document.removeEventListener('keyup', this._handleFullscreenKeyups);
        } else {
            this._enterFullscreen();
            document.addEventListener('keyup', this._handleFullscreenKeyups);
        }
    };

    /**
     * Handles entering or exiting fullscreen mode.
     */
    _handleFullscreenChange = (e) => {
        const numberOfThumbs = this._cache.thumbnails.length;
        const thumbnail = Array.from(this._cache.thumbnails)[0];
        this._cache.component.classList.toggle('fullscreen');
        if ($(this._cache.component).width() >= numberOfThumbs * thumbnail.scrollWidth) {
            this._cache.thumbnailList.classList.toggle('centered');
        }
        this._toggleFullscreenIcon();
    };

    /**
     * Handles a keypress to the left or right arrow while in fullscreen mode.
     */
    _handleFullscreenKeyups = (e) => {
        if (e.keyCode === 37) {
            // when left arrow is pressed
            this._onLeftArrowClick();
        } else if (e.keyCode === 39) {
            // when right arrow is pressed
            this._onRightArrowClick();
        }
    };

    /**
     * Toggles the fullscreen icon ('expand') with the exit fullscreen icon ('compress')
     */
    _toggleFullscreenIcon = () => {
        Array.from(this._cache.fullscreenIcons).forEach((icon) => {
            icon.classList.toggle('fa-expand');
            icon.classList.toggle('fa-compress');
        });
    };

    /**
     * Handles a click to the social share button.
     */
    _onSocialShareClick = (e) => {
        if (e.currentTarget.className === 'social-share-container visible') {
            e.currentTarget.classList.remove('visible');
        } else {
            e.currentTarget.className += ' visible';
        }
    };

    /**
     * Handles a click to a specific social sharing link/icon.
     */
    _onSocialLinkClick = (e) => {
        let shareableUrl = window.location.href + '?i=' + this._cache.currentIndex;
        const link = e.currentTarget;
        let mediaPrefix = '';
        let windowName = '';

        if (link.classList.contains('facebook')) {
            if (window.fbAsyncInit) {
                this._onFacebookClick(shareableUrl);
                return;
            } else {
                //uninitialized Facebook JavaScript SDK
                mediaPrefix = 'https://www.facebook.com/sharer/sharer.php?u=';
                shareableUrl = window.location.href;
                windowName = 'Facebook';
            }
        } else if (link.classList.contains('twitter')) {
            mediaPrefix = 'https://twitter.com/home?status=';
            windowName = 'Twitter';
        } else if (link.classList.contains('email')) {
            mediaPrefix = 'mailto:recipient@example.com&body=';
        }

        const fullLink = mediaPrefix + shareableUrl;

        window.open(fullLink, windowName, 'height=300, width=550, resizable=1');
    };

    /**
     * Shares an individual gallery image on Facebook.
     */
    _onFacebookClick = (shareableUrl) => {
        const FB = window.FB;
        const $currentImage = $('.gallery-image.visible');
        const imageUrl = $currentImage.find('.filer-image').attr('src');
        const imageDescription = $currentImage.find('.img-description').text();

        FB.ui(
            {
                method: 'feed',
                picture: imageUrl,
                link: shareableUrl,
                caption: imageDescription
            },
            function (response) {}
        );
    };

    /*
     * Adds event handlers.
     */
    _addEvents = () => {
        const $window = $(window);
        const $component = $(this._cache.component);
        const $thumbnailArrows = $(this._cache.thumbnailArrows);
        const numberOfThumbs = this._cache.thumbnails.length;
        const thumbnail = Array.from(this._cache.thumbnails)[0];

        if (this._cache.leftArrow) {
            // Decrement current index
            this._cache.leftArrow.addEventListener('click', this._onLeftArrowClick);
            this._cache.leftArrow.addEventListener('keyup', (e) => {
                if (e.keyCode === 13) {
                    //ENTER key
                    this._onLeftArrowClick();
                }
            });
        }

        if (this._cache.rightArrow) {
            // Increment current index
            this._cache.rightArrow.addEventListener('click', this._onRightArrowClick);
            this._cache.rightArrow.addEventListener('keyup', (e) => {
                if (e.keyCode === 13) {
                    this._onRightArrowClick();
                }
            });
        }

        if (this._cache.thumbnails.length > 0) {
            // Switch current index to that of clicked thumbnail image
            Array.from(this._cache.thumbnails).forEach((thumbnailEl) => {
                thumbnailEl.addEventListener('click', this._onThumbnailClick);
            });

            // Scroll to left or right of thumbnail list
            Array.from(this._cache.thumbnailArrows).forEach((thumbnailArrow) => {
                thumbnailArrow.addEventListener('click', this._onThumbnailArrowClick);
            });

            // Hide or display thumbnail arrows when window is resized
            var resizeTimer;
            window.addEventListener('resize', (e) => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    if (numberOfThumbs * $(thumbnail).outerWidth() <= $component.width()) {
                        $thumbnailArrows.each(function (i, arrow) {
                            $(arrow).addClass('hidden');
                        });
                    } else {
                        $thumbnailArrows.each(function (i, arrow) {
                            $(arrow).removeClass('hidden');
                        });
                    }
                }, 250);
            });
        }

        if (this._cache.fullscreenButtons) {
            // Allow gallery to take up full screen when button is clicked
            Array.from(this._cache.fullscreenButtons).forEach((button) => {
                button.addEventListener('click', this._onFullscreenClick);
            });
        }

        if (this._cache.socialShareContainers) {
            // Open social media sharing options when button is clicked
            Array.from(this._cache.socialShareContainers).forEach((button) => {
                button.addEventListener('click', this._onSocialShareClick);
            });
            // Set social sharing link destinations
            Array.from(this._cache.socialLinks).forEach((link) => {
                link.addEventListener('click', this._onSocialLinkClick);
            });
        }

        // cross-browser event listener for fullscreen change
        if (document.exitFullscreen) {
            document.addEventListener('fullscreenchange', this._handleFullscreenChange);
        } else if (document.mozCancelFullScreen) {
            document.addEventListener('mozfullscreenchange', this._handleFullscreenChange);
        } else if (document.webkitExitFullscreen) {
            document.addEventListener('webkitfullscreenchange', this._handleFullscreenChange);
        } else if (document.msExitFullscreen) {
            document.addEventListener('msfullscreenchange', this._handleFullscreenChange);
        }
    };

    /*
     * Prepares the gallery features on page load.
     */
    _handleDisplay = () => {
        if (this._cache.body) {
            // display gallery image
            const currentImage = document.getElementById(
                'image-' + this.id + '-' + this._cache.currentIndex
            );
            currentImage.className += ' visible';

            // indicate which thumbnail corresponds to current gallery image
            if (this._cache.thumbnails.length > 0) {
                const currentThumbnail = document.getElementById(
                    'thumbnail-' + this.id + '-' + this._cache.currentIndex
                );
                currentThumbnail.className += ' selected';
            }

            // determine if thumbnail arrows should be hidden on page load
            // first define a single thumbnail in order to calculate its width
            if (this._cache.thumbnails.length) {
                const thumbnail = Array.from(this._cache.thumbnails)[0];
                if (
                    this._cache.thumbnails.length * $(thumbnail).outerWidth() <=
                    $(this._cache.component).width()
                ) {
                    Array.from(this._cache.thumbnailArrows).forEach((arrow) => {
                        arrow.className += ' hidden';
                    });
                }
            }
        }
    };
}
