
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom'
import River from './river.jsx';
import { rstrip } from '../utils';

class BlogRiver {

    constructor(options) {
        this.data = options.data;
        this.settings = options.settings;
        this.component = options.component;
        this.site = options.site;
        this.columnSize = options.columnSize;
        this.isPreview = (options.isPreview ? true : false);
        this.featureToggles = options.switches;

        const apiBaseURL = rstrip(options.APIBaseURL, '/');
        this.API = {
            BLOG_CATEGORIES_URL: `${apiBaseURL}/bento-api/blogs/categories/`,
            BLOG_ENTRIES_URL: `${apiBaseURL}/bento-api/blogs/entries/`,
            BLOG_ENTRY_URL: `${apiBaseURL}/bento-api/blogs/entries/{blogEntryId}/`,
            BLOG_TAGS_URL: `${apiBaseURL}/bento-api/blogs/tags/`
        };
        this.basePath = window.location.pathname;
        this.baseHost = window.location.host;

        this.init();
    }

    init = () => {
        ReactDOM.render(
            <BrowserRouter>
                <Route path={this.basePath} render={(props) => {
                        return (
                            <River {...this.props}
                                data={this.data}
                                settings={this.settings}
                                API={this.API}
                                site={this.site}
                                columnSize={this.columnSize}
                                basePath={this.basePath}
                                baseHost={this.baseHost}
                                isPreview={this.isPreview}
                                featureToggles={this.featureToggles}
                                />
                        );
                    }} />
            </BrowserRouter>,
            this.component
        );
    }
}

export default BlogRiver;
