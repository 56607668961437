import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Theme extends Component {
    static propTypes = {
        namespace: PropTypes.string.isRequired,
        colorPalette: PropTypes.object.isRequired
    };

    static defaultProps = {
        colorPalette: {}
    };

    render() {
        const { namespace, colorPalette } = this.props;

        return (
            <style id={`theme-${namespace}`}>
                {`
                #${namespace} .video-cta {
                    border-color: ${colorPalette.main};
                    color: ${colorPalette.main};
                }

                #${namespace} .video-cta .video-cta-text {
                    color: ${colorPalette.main};
                }

                #${namespace} .video-cta:hover,
                #${namespace} .video-cta:focus {
                    background-color: ${colorPalette.accent1};
                    border-color: ${colorPalette.accent1};
                    color: ${colorPalette.accent4};
                }

                #${namespace} .video-cta:hover .video-cta-text,
                #${namespace} .video-cta:focus .video-cta-text {
                    color: ${colorPalette.accent4};
                }

                #${namespace} .load-more{
                    color: ${colorPalette.main};
                }

                #${namespace} .load-more:hover,
                #${namespace} .load-more:focus {
                    background-color: ${colorPalette.accent1};
                    border-color: ${colorPalette.accent1};
                    color: ${colorPalette.accent4};
                }
            `}
            </style>
        );
    }
}
