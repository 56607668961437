import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Article extends Component {
    static propTypes = {
        article: PropTypes.shape({
            title: PropTypes.string,
            image: PropTypes.string,
            summary: PropTypes.string,
            link: PropTypes.string,
            pubDate: PropTypes.string
        }).isRequired,
        showImage: PropTypes.bool,
        showTitle: PropTypes.bool,
        showDescription: PropTypes.bool,
        showCTA: PropTypes.bool,
        showPubDate: PropTypes.bool
    };

    getAltFromImage = (value) => {
        const frag = document.createRange().createContextualFragment(value);
        const img = frag.querySelector('img');
        if(img) {
            return img.getAttribute('alt') || '';
        } else {
            return frag.textContent;
        }
    }

    render() {
        const { article: {title, image, content, summary, link = '', pubDate }, showImage,
                showTitle, showDescription, showCTA, showPubDate } = this.props;
        const altText = (content && content.value) ?
                        this.getAltFromImage(content.value) :
                        '';
        let articleDate = new Date(pubDate).toLocaleDateString();
        if (!title && !summary) { return null; }

        return (
        <li className='media'>
            <article className='media-body item-entry'>
                {title && showTitle &&
                    <h3 className='item-heading'>
                        <a href={link}
                        className='read-more colorPalette-accent1'
                        target='_blank'
                        rel='noopener noreferrer'>
                            {title}
                        </a>
                    </h3>
                }

                {image && showImage &&
                    <img loading='lazy' className='item-image' src={image} alt={altText}/>
                }

                {summary && showDescription &&
                    <div className='summary item-description'
                        dangerouslySetInnerHTML={{__html: summary}}
                        />
                }

                <div className='media-footer'>
                    {pubDate && showPubDate &&
                        <div className='item-pub-date'>Published on: {articleDate}</div>
                    }
                    {link && showCTA &&
                        <a href={link}
                        className='read-more'
                        target='_blank'
                        rel='noopener noreferrer'>
                            Read More <i className='fas fa-angle-right' aria-hidden='true'></i>
                            <span className='hidden-a11y'>on {title}</span>
                        </a>
                    }
                </div>
                
            </article>
        </li>
        );
    }
}
