import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './pager-widget.scss';

class PagerWidget extends Component {
    static propTypes = {
        goToNext: PropTypes.func.isRequired,
        goToPrevious: PropTypes.func.isRequired,
        pageCount: PropTypes.number.isRequired,
        handlePageChanged: PropTypes.func.isRequired,
        currentPage: PropTypes.number,
        handleClick: PropTypes.func
    };

    static defaultProps = {
        currentPage: 0,
        handleClick: () => {}
    };

    render() {
        const {
            goToPrevious,
            goToNext,
            pageCount,
            currentPage,
            handlePageChanged,
            handleClick
        } = this.props;

        const previousLabel = (
            <React.Fragment>
                <span className="hidden-a11y">previous page</span>
                <i
                    className="fas fa-angle-left"
                    aria-hidden="true"
                    onClick={(e) => {
                        goToPrevious(e);
                    }}
                />
            </React.Fragment>
        );

        const nextLabel = (
            <React.Fragment>
                <span className="hidden-a11y">next page</span>
                <i
                    className="fas fa-angle-right"
                    aria-hidden="true"
                    onClick={(e) => {
                        goToNext(e);
                    }}
                />
            </React.Fragment>
        );

        return (
            <div onClick={handleClick}>
                <ReactPaginate
                    containerClassName={`pager-widget ${styles.pager}`}
                    subContainerClassName="pages pagination"
                    previousLabel={previousLabel}
                    nextLabel={nextLabel}
                    pageCount={pageCount}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    initialSelected={currentPage}
                    forcePage={currentPage}
                    breakLabel="..."
                    breakClassName="break-me"
                    onPageChange={handlePageChanged}
                    activeClassName="active"
                />
            </div>
        );
    }
}

export default PagerWidget;
