import facebookIcon from './media/facebook.svg';
import twitterIcon from './media/twitter.svg';
import mailIcon from './media/mail.svg';
import { shareOnFacebook, shareOnTwitter, shareOnMail } from '../../../frontend_js/utils';

const social = [
    {
        name: 'Facebook',
        displayName: 'Facebook',
        icon: facebookIcon,
        action: shareOnFacebook
    },
    {
        name: 'Twitter',
        displayName: 'Twitter',
        icon: twitterIcon,
        action: shareOnTwitter
    },
    {
        name: 'Email',
        displayName: 'Email',
        icon: mailIcon,
        action: shareOnMail
    }
];

export default social;
