import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Theme extends Component {
    static propTypes = {
        namespace: PropTypes.string.isRequired,
        colorPalette: PropTypes.object.isRequired
    };

    static defaultProps = {
        colorPalette: {}
    };

    render() {
        const { namespace, colorPalette } = this.props;

        return (
            <style id={`theme-${namespace}`}>
                {`
                #${namespace} .load-more,
                #${namespace} a.cta,
                #${namespace} a.cta:visited,
                #${namespace} a.cta *,
                #${namespace} .tag:not(.active) a {
                    color: ${colorPalette.main};
                }

                #${namespace} .filters.categories .filter {
                     border: 1px solid ${colorPalette.main};
                }

                #${namespace} .filters.categories .filter:hover,
                #${namespace} .filters.categories .filter:focus,
                #${namespace} .filters.categories .filter.active {
                    background-color: ${colorPalette.accent1};
                    border: 1px solid ${colorPalette.accent1};
                }

                #${namespace} .load-more:hover,
                #${namespace} .load-more:focus {
                    border: 2px solid ${colorPalette.accent1};
                    color: ${colorPalette.accent4};
                    background-color: ${colorPalette.accent1};
                }

                #${namespace} a.cta:hover,
                #${namespace} a.cta:focus,
                #${namespace} a.cta:hover *,
                #${namespace} a.cta:focus * {
                    color: ${colorPalette.accent1};
                }

                #${namespace} .filters.categories .filter a:hover,
                #${namespace} .filters.categories .filter a:hover,
                #${namespace} .filters.categories .filter.active a {
                    color: ${colorPalette.accent4};
                }

                #${namespace} .filters.tags .filter a:hover,
                #${namespace} .filters.tags .filter a:hover,
                #${namespace} .filters.tags .filter.active a {
                    color: ${colorPalette.accent1};
                    text-decoration: underline;
                }

            `}
            </style>
        );
    }
}
