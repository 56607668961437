//Disqus universal code @ https://disqus.com/admin/universalcode/

export default function initDisqus(page, pageId, disqusShortname) {

    var disqus_config = function (pageUrl, pageId) {
        this.page.url = page;
        this.page.identifier = pageId;
    };
    (function() {
        var d = document, s = d.createElement('script');

        s.src = 'https://' + disqusShortname + '.disqus.com/embed.js';

        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
    })();
}
