/* global $ */

export default class Carousel {

    constructor(options) {
        this._cache = {};
        this._component = options.component;
        this._slidesData = options.slidesData;
        this._slidesSettings = options.slidesSettings;
        this._settings = options.settings;
        this._elemCache = {
            carousel: this._component.querySelector('.carousel'),
            slides: this._component.getElementsByClassName('slide'),
            indicators: this._component.getElementsByClassName('indicator'),
            arrowLeft: this._component.querySelector('.arrow-left'),
            arrowRight:this._component.querySelector('.arrow-right')
        };
        this._currentIndex = 0;
        this._animate = true;
        this._animationSuspended = false;
        this.init();
    }

    init = () => {
        this.setSlides(0);
        this.initArrows();
        this.initIndicators();
        this.initAnimation();
    }

    initArrows = () => {
        if(this._elemCache.arrowLeft){
            this._elemCache.arrowLeft.addEventListener('click', () => {
                clearTimeout(this._timer);
                this.prevSlide();
            });
        }
        if(this._elemCache.arrowRight){
            this._elemCache.arrowRight.addEventListener('click', () => {
                clearTimeout(this._timer);
                this.nextSlide();
            });
        }
    }

    initIndicators = () => {
        const indicatorsLength = this._elemCache.indicators ? this._elemCache.indicators.length : 0;

        for(let i = 0; i < indicatorsLength; i++){
            let indicator =  this._elemCache.indicators[i];
            const slideIndex = i + 1;
            const currentSlideTitle = (this._slidesData &&
                                        this._slidesData[i] &&
                                        this._slidesData[i].title) ||
                                        `Slide ${slideIndex}`;
            indicator.setAttribute('title', `Go to ${currentSlideTitle}`);
            indicator.addEventListener('click', () => {
                this.setSlides(i);
            });
        }
    }

    initAnimation = () => {
        let slides = this._elemCache.slides;
        const slidesLength = this._elemCache.slides ? slides.length : 0;

        for(let i = 0; i < slidesLength; i++) {
            let touchstartX = 0;
            let touchstartY = 0;
            let touchendX = 0;
            let touchendY = 0;

            this._elemCache.slides[i].addEventListener('touchstart', (event) => {
                touchstartX = event.changedTouches[0].screenX;
                touchstartY = event.changedTouches[0].screenY;
                this.suspendAnimation();
            });

           this._elemCache.slides[i].addEventListener('touchend', (event) => {
                touchendX = event.changedTouches[0].screenX;
                touchendY = event.changedTouches[0].screenY;
                this._animate = true;
                this._animationSuspended = false;
                if(touchendX < touchstartX) {
                    this.nextSlide();
                }
                if(touchendX > touchstartX) {
                    this.prevSlide();
                }
            });

            this._elemCache.slides[i].addEventListener('mouseenter', () => {
                if(this._animate && !this._animationSuspended){
                    this.suspendAnimation();
                }
            });
            this._elemCache.slides[i].addEventListener('mouseleave', () => {
                if(!this.animate && this._animationSuspended) {
                    this.startAnimation();
                }
            });

            this._elemCache.slides[i].addEventListener('focusin', () => {
                if(this._animate && !this._animationSuspended){
                    this.suspendAnimation();
                }
            });
            this._elemCache.slides[i].addEventListener('focusout', () => {
                if(!this.animate && this._animationSuspended) {
                    this.startAnimation();
                }
            });

            const startButton =  this._component.querySelector('.animation-start');
            const stopButton =  this._component.querySelector('.animation-stop');

            if(stopButton) {
                stopButton.addEventListener('click', () => {
                    this.stopAnimation();
                });
            }
            if(startButton) {
                 startButton.addEventListener('click', () => {
                    this.startAnimation();
                });
            }
        }
        this.startAnimation();
    }

    startAnimation = () => {
        this._elemCache.carousel.classList.add('animating');
        this._animate = true;
        this._animationSuspended = false;
        this._timer = setTimeout(this.nextSlide, 5000);
    }

    stopAnimation = () => {
        this._elemCache.carousel.classList.remove('animating');
        this._animate = false;
        this._animationSuspended = false;
        clearTimeout(this._timer);
    }

    suspendAnimation = () => {
        this._animate = false;
        this._animationSuspended = true;
        clearTimeout(this._timer);
    }

    getPreviousSlideIndex = () => {
        const slidesLength = this._elemCache.slides ? this._elemCache.slides.length : 0;
        let prevIndex = this._currentIndex - 1;

        if(prevIndex < 0) {
          prevIndex = slidesLength - 1;
        }
        return prevIndex;
    }

    getNextSlideIndex = () => {
        const slidesLength = this._elemCache.slides ? this._elemCache.slides.length : 0;
        let nextIndex = this._currentIndex + 1;

        if(nextIndex === slidesLength) {
            nextIndex = 0;
        }
        return nextIndex;
    }


    prevSlide = () => {
        const newCurrent = this.getPreviousSlideIndex();

        this.setSlides(newCurrent);

        if(this._animate){
            this._timer = setTimeout(this.nextSlide, 5000);
        }
    }

    nextSlide = () => {
        const newCurrent = this.getNextSlideIndex();

        this.setSlides(newCurrent);

        if(this._animate){
            this._timer = setTimeout(this.nextSlide, 5000);
        }
    }

    // Function to set a slide the current slide
    setSlides = (newCurrent) => {
        this._currentIndex = newCurrent;
        let slides = this._elemCache.slides;
        let indicators = this._elemCache.indicators;
        const slidesLength = this._elemCache.slides ? slides.length : 0;
        const previousSlideIndex = this.getPreviousSlideIndex();
        const nextSlideIndex = this.getNextSlideIndex();
        const previousSlideTitle = (this._slidesData &&
                                   this._slidesData[previousSlideIndex] &&
                                   this._slidesData[previousSlideIndex].title) ||
                                   'Previous Slide';
         const nextSlideTitle = (this._slidesData &&
                                 this._slidesData[nextSlideIndex] &&
                                 this._slidesData[nextSlideIndex].title) ||
                                 'Next Slide';

        if(slidesLength > 1){
            this._elemCache.arrowLeft.setAttribute('title', `Go to ${previousSlideTitle}`);
            this._elemCache.arrowRight.setAttribute('title', `Go to ${nextSlideTitle}`);

            // Reset slide and indicators classes
            for (let i = slidesLength - 1; i >= 0; i--) {
                slides[i].classList.remove('current');
                slides[i].setAttribute('aria-hidden', 'true');
                indicators[i].classList.remove('active');
            }
            indicators[newCurrent].classList.add('active');
        }
        slides[newCurrent].classList.add('current');
        slides[newCurrent].removeAttribute('aria-hidden');
    }
}
