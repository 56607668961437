const ctaTpl = ({ text, textPlaceholder, url, target, enabled, pillButtonContent }) => `
        ${enabled ?
        `<a class="poster-details-cta ${pillButtonContent}" href="${url}" target="${target}" ${ target === '_blank' ? 'rel="noopener"' : '' }>` :
        '<div class="poster-details-cta '+ pillButtonContent +'">'
        }
            ${text || textPlaceholder}
        ${enabled ? '</a>' : '</div>'}
    </div>
    <br />
`;

export const posterTpl = ({title,
                titlePlaceholder,
                description,
                descriptionPlaceholder,
                cta},
                {showPosterTitle,
                showPosterDescription,
                showPosterCTA}) => `
    ${showPosterTitle ?
        `<h4 class="poster-details-title">${title || titlePlaceholder}</h4>` : ''
    }
    ${showPosterDescription ?
        `<p class="poster-details-description">${description || descriptionPlaceholder}</p>` : ''
    }
    ${showPosterCTA ? `${cta.map(ctaTpl).join('')}` : ''}
`;
