/* global $ */
import { settingsToQueryString } from '../../app/bento-components/video-list/video-utils';

export function getVideos(API, settings, showLoading = () => {}) {
    showLoading();

    const data = settings.selectedRegions
        ? {
              country: settings.selectedRegions
          }
        : null;

    return $.ajax({
        method: 'GET',
        url: `${API.MEDIA_MANAGER_ASSETS}${settingsToQueryString(settings)}`,
        data,
        type: 'json',
        cache: false
    });
}
