import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import BlogEntry from './blog-entry';
import PagerWidget from 'widgets/pager-widget/pager-widget';
import { PAGINATION_TYPES } from './metadata';
import styles from './blog-river.scss';

export class BlogRiverCommon extends Component {
    static propTypes = {
        title: PropTypes.element.isRequired,
        categories: PropTypes.array.isRequired,
        tags: PropTypes.array,
        entries: PropTypes.array.isRequired,
        zeroBasedPage: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        pageCount: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        settings: PropTypes.object.isRequired,
        setCurrentPage: PropTypes.func.isRequired,
        setEntries: PropTypes.func.isRequired,
        toggleFilter: PropTypes.func.isRequired,
        filters: PropTypes.object.isRequired,
        social: PropTypes.array.isRequired,
        entriesPerRow: PropTypes.number,
        featuredEntries: PropTypes.array,
        linkTarget: PropTypes.string,
        isPreview: PropTypes.bool.isRequired,
        isBlogRiverTitleEmpty: PropTypes.bool,
        featureToggles: PropTypes.object.isRequired,
        currentSite: PropTypes.shape({
            isPillButtonAvailable: PropTypes.bool
        })
    };

    static defaultProps = {
        entriesPerRow: 3,
        linkTarget: '_self',
        tags: []
    };

    constructor(props) {
        super(props);
        this.handlePageChanged = this.handlePageChanged.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.blogRiverRef = React.createRef();
        this.blogRiverRowRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const {
            settings: { paginationType }
        } = this.props;
        if (this.props.entries.length === 0) {
            return;
        }
        if (
            prevProps.currentPage !== this.props.currentPage &&
            paginationType === PAGINATION_TYPES.PAGINATION &&
            this.blogRiverRef.current
        ) {
            this.blogRiverRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (
            prevProps.entries.length !== this.props.entries.length &&
            prevProps.entries.length > 0 && //prevents scroll to blog river on init
            paginationType === PAGINATION_TYPES.LOAD_MORE &&
            this.blogRiverRowRef.current
        ) {
            this.blogRiverRowRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    fillEntries = (entries) => {
        const { entriesPerRow } = this.props;
        // need empty bootstrap columns
        while (entries.length % entriesPerRow !== 0) {
            entries.push(undefined);
        }

        return entries;
    };

    handlePageChanged(page) {
        const { setEntries, setCurrentPage, currentPage } = this.props;
        if (currentPage !== page.selected) {
            setCurrentPage(page.selected);
            setEntries(page.selected);
        }
    }

    handleLoadMore() {
        const { currentPage, setEntries, setCurrentPage } = this.props;
        setCurrentPage(currentPage + 1);
        setEntries(currentPage + 1);
    }

    renderFeaturedEntries = () => {
        const {
            featuredEntries,
            settings,
            data,
            social,
            linkTarget,
            featureToggles,
            isPreview
        } = this.props;
        let result = [];

        featuredEntries.map((featuredEntry, rowIndex) => {
            result.push(
                <div className="row" key={`row-featured-${rowIndex}`}>
                    <div className="col-md-12" key={`featured-${rowIndex}`}>
                        {featuredEntry && (
                            <BlogEntry
                                {...featuredEntry}
                                data={data}
                                social={social}
                                settings={settings}
                                orientation="horizontal"
                                renderFilter={this.renderFilter}
                                linkTarget={linkTarget}
                                featureToggles={featureToggles}
                                isPreview={isPreview}
                            />
                        )}
                    </div>
                </div>
            );
        });
        return result;
    };

    renderFilter = (filters, name) => {
        if (filters && filters.length) {
            const { toggleFilter } = this.props;
            const activeFilters = this.props.filters[name] || [];
            let isTagFilter = name === 'tags';
            const ascendingSort = (currentFilter, nextFilter) => {
                if (!isTagFilter) {
                    const currentSlug = currentFilter.slug.toLowerCase();
                    const nextSlug = nextFilter.slug.toLowerCase();

                    if (currentSlug < nextSlug) return -1;
                    if (currentSlug > nextSlug) return 1;

                    return 0;
                }

                return currentFilter - nextFilter;
            };

            return filters.sort(ascendingSort).map((filter) => {
                let isActive = activeFilters.findIndex((f) => f.id === filter.id) > -1;
                let cls = classnames({ active: isActive });

                return (
                    <li className={`filter ${cls}`} key={filter.id}>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                toggleFilter(filter, name);
                            }}>
                            {isTagFilter && <i className="fas fa-tags"></i>}
                            {filter.title || filter.name}
                        </a>
                    </li>
                );
            });
        }
        return null;
    };

    renderEntries = (items) => {
        const {
            entriesPerRow,
            settings,
            data,
            social,
            linkTarget,
            currentPage,
            featureToggles,
            isPreview
        } = this.props;
        const colSize = parseInt(12 / entriesPerRow, 10);
        const entries = this.fillEntries(items);
        let result = [];
        for (let rowIndex = 0; rowIndex < entries.length; rowIndex += entriesPerRow) {
            let rowProps;
            if (rowIndex === (currentPage - 1) * settings.itemsPerPage) {
                rowProps = {
                    ref: this.blogRiverRowRef
                };
            }
            result.push(
                <div className="row" key={`row-${rowIndex}`} {...rowProps}>
                    {entries.slice(rowIndex, rowIndex + entriesPerRow).map((entry, colIndex) => {
                        return (
                            <div className={`col-md-${colSize}`} key={colIndex}>
                                {entry && (
                                    <BlogEntry
                                        {...entry}
                                        data={data}
                                        social={social}
                                        settings={settings}
                                        renderFilter={this.renderFilter}
                                        linkTarget={linkTarget}
                                        featureToggles={featureToggles}
                                        isPreview={isPreview}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return result;
    };

    render() {
        const {
            categories,
            entries,
            title,
            zeroBasedPage,
            currentPage,
            pageCount,
            setCurrentPage,
            settings: { paginationType, showBlogRiverTitle = true },
            isPreview,
            isBlogRiverTitleEmpty,
            currentSite
        } = this.props;
        const displayCategories = !!(categories && categories.length > 1);
        const displayBlogRiverTitle =
            showBlogRiverTitle && (!isPreview || (isPreview && !isBlogRiverTitleEmpty));
        const pillButtonComponent = currentSite?.isPillButtonAvailable
            ? 'pill-button-component'
            : '';

        return (
            <div className={`${styles.blogRiver}`} ref={this.blogRiverRef}>
                {displayBlogRiverTitle && title}
                {displayCategories && (
                    <ul className="filters categories">
                        {this.renderFilter(categories, 'categories')}
                    </ul>
                )}
                {this.renderFeaturedEntries()}
                {this.renderEntries(entries)}

                {paginationType === PAGINATION_TYPES.PAGINATION && pageCount > 1 && (
                    <PagerWidget
                        pageCount={pageCount}
                        currentPage={zeroBasedPage}
                        goToNext={() => setCurrentPage(currentPage + 1)}
                        goToPrevious={() => setCurrentPage(currentPage)}
                        handlePageChanged={(page) =>
                            this.handlePageChanged({ selected: page.selected + 1 })
                        }
                    />
                )}

                {paginationType === PAGINATION_TYPES.LOAD_MORE && currentPage < pageCount && (
                    <div className="load-more-wrapper">
                        <button
                            onClick={this.handleLoadMore}
                            className={`load-more ${pillButtonComponent}`}>
                            Load more
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default BlogRiverCommon;
