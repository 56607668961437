import PropTypes from 'prop-types';
import React, { Component } from 'react';

import moment from 'moment';
import classnames from 'classnames';
import { updateUrlParams } from 'utils';
import { paths } from 'constants';

import defaultImage from './media/default-image.svg';
import styles from './blog-entry.scss';
import { getITSurl, isImgFormat, isDefaultImage } from 'utils';

export class BlogEntry extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
        category: PropTypes.object.isRequired,
        settings: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        social: PropTypes.array.isRequired,
        author: PropTypes.string,
        image: PropTypes.string,
        url: PropTypes.string,
        linkTarget: PropTypes.string,
        orientation: PropTypes.string,
        renderFilter: PropTypes.func.isRequired,
        entryShowImage: PropTypes.bool.isRequired,
        entryShowAuthor: PropTypes.bool.isRequired,
        entryShowPostDate: PropTypes.bool.isRequired,
        entryShowSocialIcons: PropTypes.bool.isRequired,
        imageAltText: PropTypes.string,
        featureToggles: PropTypes.object,
        isPreview: PropTypes.bool.isRequired,
        currentCategoryTitle: PropTypes.string
    };

    static defaultProps = {
        image: defaultImage,
        imageAltText: 'poster image',
        author: '',
        url: '',
        orientation: 'vertical',
        linkTarget: '_self',
        featureToggles: {}
    };

    getImgSrcset = (url) => {
        const sizes = {
            1920: url + '?resize=960x,no-scale-up 960w,',
            1200: url + '?resize=599x,no-scale-up 599w,',
            991: url + '?resize=495x,no-scale-up 495w,',
            767: url + '?resize=767x,no-scale-up 767w,',
            575: url + '?resize=575x,no-scale-up 575w,'
        };

        return Object.values(sizes).reduce((srcset, resizeUrl) => {
            return srcset + resizeUrl;
        }, '');
    };

    getResponsiveImgAttrs = (imageObject) => {
        let responsiveImgAttrs = {};
        if (imageObject && !isImgFormat(imageObject.url) && !isDefaultImage(imageObject)) {
            responsiveImgAttrs.sizes =
                '(min-width: 1200px) 960px,\n' +
                ' ((min-width: 992px) and (max-width: 1199px)) 599px,\n' +
                ' ((min-width: 768px) and (max-width: 991px)) 495px,\n' +
                ' ((min-width: 576px) and (max-width: 767px)) 767px,\n' +
                ' (max-width: 575px) 575px';
            responsiveImgAttrs.srcSet =
                imageObject && this.getImgSrcset(getITSurl(imageObject.url));
        }
        return responsiveImgAttrs;
    };

    renderImageSection = () => {
        const {
            settings,
            image,
            category,
            entryShowImage,
            imageAltText,
            isPreview,
            linkTarget,
            url,
            featureToggles
        } = this.props;

        const displayImage = settings.showImage && entryShowImage;
        let responsiveImgAttrs = this.getResponsiveImgAttrs({ url: image });

        const categoryLinkPath = isPreview
            ? category.url
            : updateUrlParams(paths.PAGE_SETTINGS, {
                  siteId: category.siteId,
                  pageId: category.pageId
              });
        const categoryLinkPreviewProps = isPreview
            ? {
                  target: '_self',
                  rel: 'noopener noreferrer'
              }
            : null;

        return (
            <div>
                {displayImage && (
                    <div className="entry-image-wrapper">
                        <a
                            href={url}
                            target={linkTarget}
                            rel={linkTarget === '_blank' ? 'noopener' : undefined}
                            className="entry-image">
                            {featureToggles.ITS && (
                                <img
                                    loading="lazy"
                                    src={getITSurl(image)}
                                    alt={imageAltText || 'entry image'}
                                    {...responsiveImgAttrs}
                                />
                            )}
                            {!featureToggles.ITS && (
                                <img loading="lazy" src={image} alt={imageAltText} />
                            )}
                        </a>
                    </div>
                )}
                <div className="category-wrapper">
                    <span className="category">
                        <a
                            href={categoryLinkPath}
                            title={category.title}
                            {...categoryLinkPreviewProps}>
                            {category.title}
                        </a>
                    </span>
                </div>
            </div>
        );
    };

    renderDetailsSection = () => {
        const {
            settings,
            url,
            social,
            author,
            date,
            entryShowAuthor,
            linkTarget,
            entryShowPostDate,
            entryShowSocialIcons,
            title
        } = this.props;

        const formatedDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('MMMM D, YYYY');
        const displayAuthor = settings.showAuthor && entryShowAuthor;
        const displayPostDate = settings.showPostDate && entryShowPostDate;
        const displaySocialSharing = settings.showSocialSharing && entryShowSocialIcons;

        return (
            <div>
                <h1 className="title">
                    <a href={url} target={linkTarget}>
                        {title}
                    </a>
                </h1>
                <div className="meta">
                    {displayAuthor && (
                        <span className="byline">
                            {' '}
                            by <span className="author">{author}</span>
                        </span>
                    )}
                    {displayAuthor && displayPostDate && <span> &bull; </span>}
                    {displayPostDate && (
                        <time className="published" dateTime={date}>
                            {formatedDate}
                        </time>
                    )}
                </div>
                {displaySocialSharing && (
                    <div className="social-container">
                        {social.map((service) => {
                            let { name, icon, action } = service;
                            return (
                                settings[`show${name}`] && (
                                    <span
                                        className={`social ${name}`}
                                        key={name}
                                        onClick={() => action && action(url)}>
                                        <img src={icon} alt={name} />
                                    </span>
                                )
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    renderFooter = () => {
        const { settings, data, tags, renderFilter, url, linkTarget } = this.props;

        return (
            <div>
                {settings.showCallToAction && (
                    <a href={url} target={linkTarget} className="cta">
                        {data.callToActionText || data.callToActionTextPlaceholder}
                        <i className="fa fa-angle-right" aria-hidden="true" />
                    </a>
                )}

                <ul className="filters tags">{renderFilter(tags, 'tags')}</ul>
            </div>
        );
    };

    render() {
        const { content, settings, orientation, entryShowImage } = this.props;

        const classes = classnames({
            'with-image': settings.showImage && entryShowImage
        });

        if (orientation === 'vertical' || !settings.showImage || !entryShowImage) {
            return (
                <article className={`${styles.blogEntry} blog-entry ${classes}`}>
                    <header>
                        {this.renderImageSection()}

                        {this.renderDetailsSection()}
                    </header>
                    {content && (
                        <section className="entry-content">
                            <div
                                className="content-body"
                                dangerouslySetInnerHTML={{ __html: content }}></div>
                        </section>
                    )}
                    <footer>{this.renderFooter()}</footer>
                </article>
            );
        } else {
            return (
                <article className={`${styles.blogEntry} blog-entry ${classes}`}>
                    <div className="row">
                        <div className="col-md-8">{this.renderImageSection()}</div>
                        <div className="col-md-4">
                            {this.renderDetailsSection()}
                            {content && <div className="content-body">{content}</div>}
                            {this.renderFooter()}
                        </div>
                    </div>
                </article>
            );
        }
    }
}
export default BlogEntry;
