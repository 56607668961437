/* global $ */
import { apiEndpoints } from 'apiService';
import { ALL_CATEGORIES, ALL_TAGS } from './metadata';

export function adaptCategories(response) {
    if (!response.data) {
        return response;
    }

    return response.data.map((categ) => {
        return {
            id: categ.id,
            siteId: categ.relationships.site.data.id,
            title: categ.attributes.title,
            slug: categ.attributes.slug,
            domain: categ.attributes.domain,
            pageId: categ.attributes.page_id,
            url: categ.attributes.absolute_url
        };
    });
}

export function adaptTags(response) {
    if (!response.data) {
        return response;
    }

    return response.data.map((tag) => {
        return {
            id: tag.id,
            title: tag.attributes.name
        };
    });
}

export function adaptEntries(response, categories) {
    function findCategoryById(id) {
        let result = categories.find((c) => c.id === id) || {};

        return result;
    }
    /* eslint-disable camelcase */
    return response.data.map((entry) => {
        const {
            title,
            short_description,
            publication_date,
            custom_publication_date,
            tags,
            authors_displayname,
            poster_image,
            absolute_url,
            show_image,
            show_social_icons,
            show_post_date,
            show_author,
            image_alt_text,
            meta: { page, component } = {}
        } = entry.attributes;
        const categoryId = entry.relationships.category.data.id;
        let category = findCategoryById(categoryId) || {};
        return {
            id: entry.id,
            title: title,
            content: short_description,
            date: custom_publication_date || publication_date,
            tags: tags,
            category: category,
            author: authors_displayname,
            image: poster_image,
            url: absolute_url,
            imageAltText: image_alt_text,
            entryShowImage: show_image,
            entryShowPostDate: show_post_date,
            entryShowAuthor: show_author,
            entryShowSocialIcons: show_social_icons,
            pageId: page,
            componentId: component
        };
    });
    /* eslint-enable camelcase */
}

export function adaptEntry(response, categories) {
    /* eslint-disable camelcase */
    const {
        title,
        short_description,
        publication_date,
        tags,
        authors_displayname,
        poster_image,
        absolute_url,
        show_image,
        show_post_date,
        show_author,
        image_alt_text,
        show_social_icons,
        meta: { page, component } = {}
    } = response.data.attributes;
    const categoryId = response.data.relationships.category.data.id;
    let category = categories.find((c) => c.id === categoryId);
    return (
        category && {
            id: response.data.id,
            title: title,
            content: short_description,
            date: publication_date,
            tags: tags,
            category: category,
            author: authors_displayname,
            image: poster_image,
            url: absolute_url,
            imageAltText: image_alt_text,
            entryShowImage: show_image,
            entryShowPostDate: show_post_date,
            entryShowAuthor: show_author,
            entryShowSocialIcons: show_social_icons,
            pageId: page,
            componentId: component
        }
    );
    /* eslint-enable camelcase */
}

export function fetchEntries(
    currentPage = 1,
    itemsPerPage = 15,
    filters = {},
    ordering = '-publication_date',
    title = '',
    siteId = ''
) {
    const { categories } = filters || [];
    const { tags } = filters || [];
    const hasAllCategories = categories.findIndex((categ) => categ.id === ALL_CATEGORIES.id) > -1;
    const hasAllTags = tags.findIndex((tag) => tag.id === ALL_TAGS.id) > -1;
    const categoryIds = hasAllCategories ? '' : categories.map((c) => c.id).join(',');
    let tagIds = hasAllTags ? '' : tags.map((t) => t.id).join(',');

    return $.ajax({
        method: 'GET',
        url: apiEndpoints.BLOG_ENTRIES.replace('{category_ids}', categoryIds)
            .replace('{tag_ids}', tagIds)
            .replace('{page_size}', itemsPerPage)
            .replace('{page}', currentPage)
            .replace('{title}', title)
            .replace('{ordering}', ordering)
            .replace('{site_ids}', siteId),
        type: 'json',
        cache: false
    }).fail((xhr, status, err) => {
        console.error('Failed to get blog entries', xhr, status, err);
    });
}

export function fetchEntry(id, siteId = '') {
    return $.ajax({
        method: 'GET',
        url: apiEndpoints.BLOG_ENTRY.replace('{blogEntryId}', id).replace('{site_ids}', siteId),
        type: 'json',
        cache: false
    }).fail((xhr, status, err) => {
        console.error('Failed to get blog entry', xhr, status, err);
    });
}

export function fetchCategories(siteId = '') {
    return $.ajax({
        method: 'GET',
        url: apiEndpoints.BLOG_CATEGORIES.replace('{site_ids}', siteId),
        type: 'json',
        cache: false
    }).fail((xhr, status, err) => {
        console.error('Failed to get blog categories', xhr, status, err);
    });
}

export function fetchTags(siteId = '') {
    return $.ajax({
        method: 'GET',
        url: apiEndpoints.BLOG_TAGS.replace('{tagName}', '')
            .replace('{page_size}', '')
            .replace('{page}', '')
            .replace('{site_ids}', siteId),
        type: 'json',
        cache: false
    }).fail((xhr, status, err) => {
        console.error('Failed to get blog tags', xhr, status, err);
    });
}
