import $ from 'jquery';
global.$ = global.jQuery = $;
import '@fortawesome/fontawesome-free';
import { ProfileGrid } from './profile-grid/profile-grid';
import GoogleEventCalendar from './calendar/calendar';
import RSSFeed from './rss-feed/rss-feed';
import Menu from './menu/menu';
import Gallery from './gallery/gallery';
import BlogRiver from './blog-river/blog-river';
import BlogEntry from './blog-entry/blog-entry';
import PosterGrid from './poster-grid/poster-grid';
import VideoList from  './video-list/video-list-fe';
import SimpleButton from  './simple-button/simple-button';
import Carousel from './carousel/carousel';
import Disqus from './disqus/disqus';
import Popup from './popup/popup';

window.PBS = window.PBS || {};

window.PBS = {
    ...window.PBS,
    ProfileGrid: ProfileGrid,
    GoogleEventCalendar: GoogleEventCalendar,
    RSSFeed: RSSFeed,
    Menu: Menu,
    Gallery: Gallery,
    BlogRiver: BlogRiver,
    BlogEntry: BlogEntry,
    PosterGrid: PosterGrid,
    VideoList: VideoList,
    SimpleButton: SimpleButton,
    Carousel: Carousel,
    Disqus: Disqus,
    Popup: Popup,
};
