import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import Article from './article.jsx';

class RSS extends Component {
    static propTypes = {
        itemsPerPage: PropTypes.number.isRequired,
        channel: PropTypes.object.isRequired,
        title: PropTypes.string,
        isComponentPreview: PropTypes.bool.isRequired,
        showFeedTitle: PropTypes.bool,
        showFeedDescription: PropTypes.bool,
        showImage: PropTypes.bool,
        showTitle: PropTypes.bool,
        showDescription: PropTypes.bool,
        showCTA: PropTypes.bool,
        showPubDate: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0
        };
    }

    getPage(page = 0) {
        const { itemsPerPage, channel } = this.props;
        const start = Math.max(page, 0) * itemsPerPage;
        const end = start + itemsPerPage;
        return channel.items.slice(start, end);
    }

    handlePageChanged = page => this.setState({ currentPage: page.selected });

    jumpTo = (e, page) => {
        const { itemsPerPage, channel: { items } } = this.props;
        const maxPage = Math.ceil(items.length / itemsPerPage) - 1;
        const minPage = 0;
        let pageNum = Math.min(Math.max(page, minPage), maxPage);

        e.stopPropagation();
        this.handlePageChanged({ selected: pageNum });
    };

    render() {
        const { title, channel: { items, description }, itemsPerPage, isComponentPreview,
            showFeedTitle, showFeedDescription, showImage, showTitle, showDescription, 
            showCTA, showPubDate } = this.props;
        const { currentPage } = this.state;
        const pageItems = this.getPage(currentPage);
        const pageCount = Math.ceil(items.length / itemsPerPage);

        const previousLabel = (
            <React.Fragment>
                <span className='hidden-a11y'>previous page</span>
                <i className='fas fa-angle-left'
                    aria-hidden='true'
                    onClick={(e) => this.jumpTo(e, currentPage - 1)} />
            </React.Fragment>
        );

        const nextLabel = (
            <React.Fragment>
                <span className='hidden-a11y'>next page</span>
                <i className='fas fa-angle-right'
                    aria-hidden='true'
                    onClick={(e) => this.jumpTo(e, currentPage + 1)} />
            </React.Fragment>
        );

        return (
            <div className='rss-feed'>
                {!isComponentPreview && showFeedTitle && title &&
                    <h2>{title}</h2>
                }
                {showFeedDescription && description &&
                    <div className='feed-description'>{description}</div>
                }
                <div className='rss-articles-wrapper'>
                    <ul className='articles'>
                        {pageItems.map(
                            (article, index) => <Article key={index}
                                article={article}
                                showImage={showImage}
                                showTitle={showTitle}
                                showDescription={showDescription}
                                showCTA={showCTA}
                                showPubDate={showPubDate}
                            />
                        )}
                    </ul>
                    {pageCount > 1 &&
                        <ReactPaginate previousLabel={previousLabel}
                            nextLabel={nextLabel}
                            pageCount={pageCount}
                            pageRangeDisplayed={3}
                            initialSelected={currentPage}
                            forcePage={currentPage}
                            breakLabel='...'
                            breakClassName='break-me'
                            marginPagesDisplayed={1}
                            onPageChange={this.handlePageChanged}
                            containerClassName='pagination'
                            subContainerClassName='pages pagination'
                            activeClassName='active colorPalette-accent1'
                        />
                    }
                </div>
            </div>
        );
    }
}
export default RSS;
