import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uuid from 'uuid';

import { PAGINATION_TYPES } from './metadata';
import VideoItem from './video-item';
import VideoPlayer from './video-player';
import PagerWidget from 'widgets/pager-widget/pager-widget';

import styles from './video-list.scss';

class VideoList extends Component {
    static propTypes = {
        columnSize: PropTypes.number.isRequired,
        componentId: PropTypes.string.isRequired,
        videos: PropTypes.array.isRequired,
        handleCTAChange: PropTypes.func,
        pageCount: PropTypes.number.isRequired,
        CallToAction: PropTypes.func.isRequired,
        videoClick: PropTypes.func.isRequired,
        videoClose: PropTypes.func.isRequired,
        currentVideo: PropTypes.object,
        VideoListTitle: PropTypes.func.isRequired,
        handleChangeTitle: PropTypes.func,
        showVideo: PropTypes.bool.isRequired,
        playerClass: PropTypes.string.isRequired,
        setCurrentPage: PropTypes.func.isRequired,
        currentPage: PropTypes.number.isRequired,
        isLoadMoreEnabled: PropTypes.bool.isRequired,
        settings: PropTypes.shape({
            selectedShows: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired
                })
            ),
            itemsPerPage: PropTypes.number.isRequired,
            displayVideoTitle: PropTypes.bool.isRequired,
            displayVideoListTitle: PropTypes.bool.isRequired,
            displayShowTitle: PropTypes.bool.isRequired,
            displayVideoDescription: PropTypes.bool.isRequired,
            displayVideoMeta: PropTypes.bool.isRequired,
            includePassportVideos: PropTypes.bool.isRequired,
            videoType: PropTypes.string.isRequired,
            paginationType: PropTypes.string.isRequired,
            order: PropTypes.shape({
                value: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            }),
            player: PropTypes.shape({
                autoplay: PropTypes.bool.isRequired,
                topbar: PropTypes.bool.isRequired
            })
        }),
        data: PropTypes.shape({
            cta: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
            titlePlaceholder: PropTypes.string.isRequired
        }),
        currentSite: PropTypes.object,
        callsign: PropTypes.string
    };

    static defaultProps = {
        handleChangeTitle: () => {},
        handleCTAChange: () => {}
    };

    constructor(props) {
        super(props);

        this.loadMoreClick = this.loadMoreClick.bind(this);
        this.goToNext = this.goToNext.bind(this);
        this.goToPrevious = this.goToPrevious.bind(this);
        this.mainVideo = React.createRef();
    }

    loadMoreClick(e) {
        const { setCurrentPage, currentPage } = this.props;
        const nextPage = currentPage + 1;
        e.preventDefault();
        e.stopPropagation();
        setCurrentPage(nextPage);
    }

    goToNext = (e) => {
        e.stopPropagation();
        const { setCurrentPage, currentPage } = this.props;
        setCurrentPage(currentPage + 1);
    };

    goToPrevious = (e) => {
        e.stopPropagation();
        const { setCurrentPage, currentPage } = this.props;
        setCurrentPage(currentPage - 1);
    };

    handlePageChanged = (page) => {
        const { setCurrentPage } = this.props;
        const oneBasedPage = page.selected + 1;
        setCurrentPage(oneBasedPage);
        this.mainVideo.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center'
        });
    };

    handleVideoClick = (e, video) => {
        const { videoClick } = this.props;
        videoClick(e, video);
        //scroll to main video
        this.mainVideo.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    };

    render() {
        const {
            settings,
            settings: { displayVideoListTitle = true },
            videos,
            pageCount,
            data,
            currentVideo,
            VideoListTitle,
            CallToAction,
            handleCTAChange,
            componentId,
            columnSize,
            handleChangeTitle,
            showVideo,
            playerClass,
            callsign,
            videoClose,
            currentPage,
            isLoadMoreEnabled,
            currentSite
        } = this.props;
        const displayPagination =
            settings.paginationType === PAGINATION_TYPES.PAGINATION && pageCount > 1;
        const displayLoadMore =
            settings.paginationType === PAGINATION_TYPES.LOAD_MORE && currentPage < pageCount;
        const currentVideoId = currentVideo && currentVideo.id;
        const zeroBasedCurrentPage = Math.min(Math.max(0, currentPage - 1), pageCount);
        const pillButtonComponent = currentSite?.isPillButtonAvailable
            ? 'pill-button-component'
            : '';

        return (
            <div className={`video-list ${styles.videoList} column-${columnSize}`}>
                {displayVideoListTitle && (
                    <VideoListTitle
                        {...this.props}
                        id={componentId}
                        handleChangeTitle={handleChangeTitle}
                    />
                )}
                {!!settings.selectedShows.length && (
                    <VideoPlayer
                        {...this.props}
                        settings={settings}
                        playerSettings={settings.player}
                        currentVideo={currentVideo}
                        cta={data.cta[currentVideoId]}
                        handleCTAChange={handleCTAChange}
                        componentId={componentId}
                        CallToAction={CallToAction}
                        showVideo={showVideo}
                        playerClass={playerClass}
                        videoClose={videoClose}
                        reference={this.mainVideo}
                        callsign={callsign}
                        isPillButtonAvailable={currentSite?.isPillButtonAvailable}
                    />
                )}
                <ul className="videos">
                    {videos.map((video) => {
                        const key = video.id || uuid.v4();
                        const isActive = currentVideo && currentVideo.id === video.id;
                        if (!video) {
                            return null;
                        }
                        return (
                            <VideoItem
                                {...video}
                                key={key}
                                id={key}
                                settings={settings}
                                isActive={isActive}
                                handleClick={(e) => this.handleVideoClick(e, video)}
                                handleClose={this.handleClose}
                                componentId={componentId}
                            />
                        );
                    })}
                </ul>

                {displayPagination && (
                    <PagerWidget
                        pageCount={pageCount}
                        currentPage={zeroBasedCurrentPage}
                        goToNext={this.goToNext}
                        goToPrevious={this.goToPrevious}
                        handlePageChanged={this.handlePageChanged}
                    />
                )}

                {displayLoadMore && (
                    <div className="load-more-wrapper">
                        <a
                            href="#"
                            onClick={this.loadMoreClick}
                            className={`load-more ${
                                !isLoadMoreEnabled ? 'disabled' : ''
                            } ${pillButtonComponent}`}>
                            Load more
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default VideoList;
