/* global $ */
import './../libs/simple-pagination/jquery.simplePagination';
import 'webui-popover'; /* enhanced version of boostrap popover */
import 'webui-popover/src/jquery.webui-popover.css';
import { posterTpl } from './poster-grid-templates.js';
import 'jquery-lazy/jquery.lazy.min';

export default class PosterGrid {

    constructor(options) {
        this._computePosterHeight = this._computePosterHeight.bind(this);
        this._cache = {};
        this._component = options.component;
        this._itemsData = options.itemsData;
        this._itemsSettings = options.itemsSettings;
        this._settings = options.settings;
        this._postersPerPage = options.postersPerPage;
        this._elemCache = {
            posters: $(this._component).find('.poster'),
            loadMoreBtn: $(this._component).find('.poster-grid-btn-loadMore'),
            pager: $(this._component).find('.poster-grid-pager'),
            pagerFirst: $(this._component).find('.pager-first'),
            pagerLast: $(this._component).find('.pager-last'),
            gridImages: $(this._component).find('.grid-image')
        };
        this.isSmallBreakpoint = false;
				this._site = options.site;

        this.init();
    }

    init = () => {
        const posters = this._elemCache.posters;
        switch(this._settings.paginationType) {
            case 'pagination':
                this._initPager();
                break;
            case 'lazyLoad':
                this._initLazyLoad();
                break;
            case 'loadMore':
                this._initLoadMore();
                break;
        }
        $(window).on('load', this._computePosterHeight);
        $(window).on('resize', this._onResize).trigger('resize');

        this._elemCache.posters.click(function(e){
            posters.removeClass('active');
            $(this).addClass('active');
        });
    };

    _onResize = () => {
        this._computePosterHeight();
        this.isSmallBreakpoint = window.matchMedia('(max-width: 767px)').matches;
        this._popoverInit();
    }

    _computePosterHeight = () => {
        let posters = this._elemCache.posters;
        if(!posters) {
            return;
        }
        let posterWidth = posters.length ? $(posters[0]).width() : 0;
        for(var index = 0; index < posters.length; index++){
           $(posters[index]).height(3 / 2 * posterWidth * 1.0 + 'px');
        }
    }

    _initLoadMore = () => {
        let button = this._elemCache.loadMoreBtn;
        const posters = this._elemCache.posters;
        button.click((e) => {
            e.preventDefault();
            posters.filter(`.loadMore:lt(${this._postersPerPage})`)
                    .removeClass('loadMore');
            !posters.filter('.loadMore').length && button.hide();
        });
    }

    _initLazyLoad = () => {
        this._elemCache.gridImages.Lazy({
            scrollDirection: 'vertical',
            effect: 'fadeIn',
        });
        window.onload = function() {
            $(window).resize();
        };
    }

    _initPager = () =>{
        let posters = this._elemCache.posters;
        let postersCount = posters.length;
        let perPage = this._postersPerPage;
        let pager = this._elemCache.pager;

        pager.pagination({
            items: postersCount,
            itemsOnPage: perPage,
            displayedPages: 3,
            edges: 1,
            prevText: '<i class="fas fa-angle-left" ></i>',
            nextText: '<i class="fas fa-angle-right" ></i>',
            ellipsePageSet: false,
            hrefTextSuffix: '',
            onPageClick: function(pageNumber, e) {
                let showFrom = perPage * (pageNumber - 1);
                let showTo = showFrom + perPage;

                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }
                posters.addClass('pagination');
                posters.slice(showFrom, showTo).removeClass('pagination');
            }
        });

        this._elemCache.pagerFirst.click(function() {
           pager.pagination('selectPage', 1);
        });
        this._elemCache.pagerLast.click(function() {
            let pagesCount = pager.pagination('getPagesCount');
            pager.pagination('selectPage', pagesCount);
        });
    }

    _popoverInit = () => {
        const itemsData = this._itemsData;
        const itemsSettings = this._itemsSettings;
        const isSmallBreakpoint = this.isSmallBreakpoint;
        let options = {
            trigger: 'hover',
            placement: 'horizontal',
            width: 400,
            container: this._component,
            style: `poster-grid ${this._component.id}`,
            height: 'auto',
            animation:' pop',
            closeable: false,
            dismissible: true,
            cache: false,
            destroy: true,
            arrow: true,
            title: '',
        };

				const pillButtonContent = this._site.is_pill_button_available ? 'has-pill-button' : '';

        this._elemCache.posters.each(function(index) {
            let itemSetting = itemsSettings[index];
            let itemData = itemsData[index];

					let updatedItemData = {
						description: itemData.description,
						descriptionPlaceholder: itemData.descriptionPlaceholder,
						id: itemData.id,
						img: itemData.img,
						title: itemData.title,
						titlePlaceholder: itemData.titlePlaceholder,
						cta: []
					};

					itemData.cta.forEach(function (currentCta) {
							currentCta = {
								...currentCta,
								pillButtonContent: pillButtonContent
							}
							updatedItemData.cta.push(currentCta);
					});

            let displayPopover = itemSetting.showPosterPopover &&
                                (itemSetting.showPosterTitle ||
                                itemSetting.showPosterDescription ||
                                itemSetting.showPosterCTA);

            if (displayPopover) {
                $(this).webuiPopover('destroy');
                if (!isSmallBreakpoint) {
                    $(this).webuiPopover({
                        ...options,
                        content: posterTpl(updatedItemData, itemSetting),
                    });
                }
            }
        });
    }
}
