/* global $ */
import imagesLoaded from 'imagesloaded';

imagesLoaded.makeJQueryPlugin($);

export const ProfileGrid = function (config) {
	this.data = config.data;
	this.settings = config.settings;
	this.profiles = config.profiles;
	this.columnSize = config.columnSize;
	this.itemsPerPage = getItemsPerPage(this.columnSize);
	this.pages = paginateItems([...this.profiles], this.itemsPerPage);
	this.isPillButtonAvailable = config.site.is_pill_button_available;
	// list of items
	var $grid = $(config.snippet),
		// the items
		$items = $grid.children('li'),
		// current expanded item's index
		current = -1,
		// position (top) of the expanded item
		// used to know if the preview will expand in a different row
		previewPos = -1,
		// extra amount of pixels to scroll the window
		scrollExtra = 20,
		// extra margin when expanded (between preview overlay and the next items)
		marginExpanded = 30,
		$window = $(window),
		winsize,
		$body = $('html, body'),
		// transitionend events
		transEndEventName =
			'WebkitTransition' in document.documentElement.style
				? 'webkitTransitionEnd'
				: 'transitionend' in document.documentElement.style
				? 'transitionend'
				: false,
		// default settings
		settings = {
			speed: 350,
			easing: 'ease'
		};
	var showRelatedLinks = this.settings.showRelatedLinks;
	var showPillButton = this.isPillButtonAvailable

	function init(cfg) {
		// the settings..
		settings = $.extend(true, {}, settings, cfg);

		// preload all images
		$grid.imagesLoaded(function () {
			// save item´s size and offset
			saveItemInfo(true);
			// get window´s size
			getWinSize();
			// initialize some events
			initEvents();
		});
	}

	function getItemsPerPage(colSize) {
		if (colSize === 8) {
			return 12;
		}
		return 15;
	}

	function paginateItems(arr, itemsPerPage) {
		let page = 0;
		let pages = {};

		while (arr.length) {
			pages[page] = arr.slice(0, itemsPerPage);
			arr.splice(0, itemsPerPage);
			page++;
		}

		return pages;
	}

	// add more items to the grid.
	// the new items need to appended to the grid.
	// after that call Grid.addItems(theItems);
	function addItems($newitems) {
		$items = $items.add($newitems);

		$newitems.each(function () {
			var $item = $(this);
			$item.data({
				offsetTop: $item.offset().top,
				height: $item.find('> a').height()
			});
		});

		initItemsEvents($newitems);
	}

	// saves the item´s offset top and height (if saveheight is true)
	function saveItemInfo(saveheight) {
		$items.each(function () {
			var $item = $(this);
			$item.data('offsetTop', $item.offset().top);
			if (saveheight) {
				$item.data('height', $item.find('> a').height());
			}
		});
	}

	function initEvents() {
		// when clicking an item, show the preview with the item´s info and large image.
		// close the item if already expanded.
		// also close if clicking on the item´s cross
		initItemsEvents($items);

		// on window resize get the window´s size again
		// reset some values..
		var oldWidth = winsize.width;
		var resizeTimer;
		window.addEventListener('resize', (e) => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				scrollExtra = 0;
				previewPos = -1;
				// save item´s offset
				saveItemInfo(true);
				//Preview
				getWinSize();

				var preview = $.data($grid, 'preview');

				if (typeof preview !== 'undefined' && winsize.width !== oldWidth) {
					hidePreview();
					showPreview(preview.$item);
					oldWidth = winsize.width;
				}
			}, 250);
		});
	}

	function initItemsEvents($items) {
		$items
			.off('click')
			.on('click', 'span.og-close', function () {
				hidePreview();
				return false;
			})
			.children('a')
			.off('click')
			.on('click', function (e) {
				var $item = $(this).parent();
				// check if item already opened
				current === $item.index() ? hidePreview() : showPreview($item);
				return false;
			});
	}

	function getWinSize() {
		winsize = {
			width: $window.width(),
			height: $window.height()
		};
	}

	function showPreview($item) {
		var preview = $.data($grid, 'preview'),
			// item´s offset top
			position = $item.data('offsetTop');

		scrollExtra = 0;

		// if a preview exists and previewPos is different (different row) from item´s top then close it
		if (typeof preview !== 'undefined') {
			if (position > previewPos) {
				scrollExtra = preview.height;
			}

			hidePreview();
		}

		// update previewPos
		previewPos = position;
		// initialize new preview for the clicked item
		preview = $.data($grid, 'preview', new Preview($item));
		// expand preview overlay
		preview.open();
	}

	function hidePreview() {
		current = -1;
		var preview = $.data($grid, 'preview');
		preview.close();
		$.removeData($grid, 'preview');
	}

	// the preview obj / overlay
	function Preview($item) {
		this.$item = $item;
		this.expandedIdx = this.$item.index();
		this.create();
		this.update();
	}
	Preview.prototype = {
		create: function () {
			const pillButtonComponent = showPillButton ? 'pill-button-component' : '';
			// create Preview structure:
			const { showCallToAction = true } = config.settings.itemsSettings[this.expandedIdx];
			console.log(showCallToAction);
			this.$title = $('<h3 class="profile-grid-title-popup"></h3>');
			this.$description = $('<p class="profile-grid-description-popup"></p>');
			this.$cta = $('<div class="og-details__cta '+ pillButtonComponent +'"></div>');
			this.$additionalLinksLabel = $('<span class="related-links-label"></span>');
			this.$addLinkA = $('<a href="#" target=""></a>');
			this.$addLinkB = $('<a href="" target=""></a>');
			this.$addLinkC = $('<a href="" target=""></a>');
			this.$addLinkD = $('<a href="" target=""></a>');
			this.$details = $('<div class="og-details"></div>').append(
				this.$title,
				this.$description,
				showCallToAction && this.$cta
			);
			this.$bottomDetails = $('<div class="og-bottom-details"></div>').append(
				this.$additionalLinksLabel,
				this.$addLinkA,
				this.$addLinkB,
				this.$addLinkC,
				this.$addLinkD
			);
			this.$fullimageCredit = $('<span class="og-img-credit"></span>');
			this.$loading = $('<div class="og-loading"></div>');
			this.$fullimage = $('<div class="og-fullimg"></div>').append(this.$loading);
			this.$fullimageWrapper = $('<div class="og-fullimg-wrapper"></div>').append(
				this.$fullimage
			);
			this.$closePreview = $('<span class="og-close"></span>');
			this.$previewInner = $('<div class="og-expander-inner"></div>').append(
				this.$closePreview,
				this.$fullimageWrapper,
				this.$details,
				this.$bottomDetails
			);
			this.$previewEl = $('<div class="og-expander"></div>').append(this.$previewInner);
			// append preview element to the item
			this.$item.append(this.getEl());
			// set the transitions for the preview and the item
			if (transEndEventName) {
				this.setTransition();
			}
		},
		update: function ($item) {
			if ($item) {
				this.$item = $item;
			}

			// if already expanded remove class "og-expanded" from current item and add it to new item
			if (current !== -1) {
				var $currentItem = $items.eq(current);
				$currentItem.removeClass('og-expanded');
				this.$item.addClass('og-expanded');
				// position the preview correctly
				this.positionPreview();
			}

			// update current value
			current = this.$item.index();

			// update preview´s content
			var $itemEl = this.$item.children('a'),
				eldata = {
					altTextDetail: $itemEl.data('alttextdetail'),
					href: $itemEl.attr('href'),
					target: $itemEl.attr('target'),
					largesrc: $itemEl.data('largesrc'),
					largeimageCredit: $itemEl.data('largeimg-credit'),
					title: $itemEl.data('title'),
					description: $itemEl.data('description'),
					callToActionText: $itemEl.data('calltoactiontext')
						? $itemEl.data('calltoactiontext')
						: $itemEl.attr('href'),
					srcset: $itemEl.data('srcset'),
					sizes: $itemEl.data('sizes'),
					additionalLinksLabel: $itemEl.data('additionallinkslabel'),
					addLinkTextA: $itemEl.data('addlinktext1'),
					addLinkUrlA: $itemEl.data('addlinkurl1'),
					addLinkTargetA: $itemEl.data('addlinktarget1'),
					addLinkTextB: $itemEl.data('addlinktext2'),
					addLinkUrlB: $itemEl.data('addlinkurl2'),
					addLinkTargetB: $itemEl.data('addlinktarget2'),
					addLinkTextC: $itemEl.data('addlinktext3'),
					addLinkUrlC: $itemEl.data('addlinkurl3'),
					addLinkTargetC: $itemEl.data('addlinktarget3'),
					addLinkTextD: $itemEl.data('addlinktext4'),
					addLinkUrlD: $itemEl.data('addlinkurl4'),
					addLinkTargetD: $itemEl.data('addlinktarget4')
				};
			this.$title.html(eldata.title);
			this.$description.html(eldata.description);

			// if we have actual link data, create a link and insert it
			// bento seems to emit "#" if there is no link set
			if (eldata.href.length && eldata.href !== '#') {
				this.$href = $('<a href="" class="og-details__link" target=""></a>');
				this.$href.attr('href', eldata.href);
				this.$href.attr('target', eldata.target);
				this.$href.html(eldata.callToActionText);
				this.$cta.addClass('og-details__cta--has-link');
				this.$cta.append(this.$href);
				this.$cta.show();
			} else {
				this.$cta.html(eldata.callToActionText);
				this.$cta.addClass('og-details__cta--no-link');
				this.$cta.show();
			}

			if (eldata.largeimageCredit) {
				this.$fullimageCredit.show().html(eldata.largeimageCredit);
			} else {
				this.$fullimageCredit.hide();
			}
			this.$additionalLinksLabel.html(eldata.additionalLinksLabel);

			var emptyLinks = 0;
			if (eldata.addLinkTextA) {
				this.$addLinkA.show();
				this.$addLinkA.html(eldata.addLinkTextA);
				this.$addLinkA.attr('href', eldata.addLinkUrlA);
				this.$addLinkA.attr('target', eldata.addLinkTargetA);
				this.$addLinkA.addClass('related-link');
			} else {
				this.$addLinkA.hide();
				emptyLinks++;
			}

			if (eldata.addLinkTextB) {
				this.$addLinkB.show();
				this.$addLinkB.html(eldata.addLinkTextB);
				this.$addLinkB.attr('href', eldata.addLinkUrlB);
				this.$addLinkB.attr('target', eldata.addLinkTargetB);
				this.$addLinkB.addClass('related-link');
			} else {
				this.$addLinkB.hide();
				emptyLinks++;
			}

			if (eldata.addLinkTextC) {
				this.$addLinkC.show();
				this.$addLinkC.html(eldata.addLinkTextC);
				this.$addLinkC.attr('href', eldata.addLinkUrlC);
				this.$addLinkC.attr('target', eldata.addLinkTargetC);
				this.$addLinkC.addClass('related-link');
			} else {
				this.$addLinkC.hide();
				emptyLinks++;
			}

			if (eldata.addLinkTextD) {
				this.$addLinkD.show();
				this.$addLinkD.html(eldata.addLinkTextD);
				this.$addLinkD.attr('href', eldata.addLinkUrlD);
				this.$addLinkD.attr('target', eldata.addLinkTargetD);
				this.$addLinkD.addClass('related-link');
			} else {
				this.$addLinkD.hide();
				emptyLinks++;
			}
			if (emptyLinks === 4 || !showRelatedLinks) {
				this.$bottomDetails.hide();
			} else {
				this.$bottomDetails.show();
			}

			var self = this;

			// remove the current image in the preview
			if (typeof self.$largeImg != 'undefined') {
				self.$largeImg.remove();
			}

			// preload large image and add it to the preview
			if (self.$fullimage.is(':visible')) {
				this.$loading.show();
				$('<img/>')
					.on('load', function () {
						var $img = $(this);
						if ($img.attr('src') === self.$item.children('a').data('largesrc')) {
							self.$loading.hide();
							self.$fullimage.find('img').remove();
							self.$largeImg = $img.fadeIn(350);
							self.$fullimage.append(self.$largeImg, self.$fullimageCredit);
						}
					})
					.attr('src', eldata.largesrc)
					.attr('srcset', eldata.srcset)
					.attr('sizes', eldata.sizes)
					.attr('alt', eldata.altTextDetail);
			}
		},
		open: function () {
			setTimeout(
				$.proxy(function () {
					// set the height for the preview and the item
					this.setHeights();
					// scroll to position the preview in the right place
					this.positionPreview();
				}, this),
				25
			);
		},
		close: function () {
			var self = this,
				onEndFn = function () {
					if (transEndEventName) {
						$(this).off(transEndEventName);
					}
					self.$item.removeClass('og-expanded');
					self.$previewEl.remove();
				};

			setTimeout(
				$.proxy(function () {
					if (typeof this.$largeImg !== 'undefined') {
						this.$largeImg.fadeOut('fast');
					}
					this.$previewEl.css('height', 0);
					// the current expanded item (might be different from this.$item)
					var $expandedItem = $items.eq(this.expandedIdx);
					$expandedItem.css('margin-bottom', '').on(transEndEventName, onEndFn);

					if (!transEndEventName) {
						onEndFn.call();
					}
				}, this),
				25
			);

			return false;
		},
		calcSize: function () {
			let heightPreview;
			let itemHeight;
			const ogExpander = this.$item.find('.og-expander-inner');

			// first set the width, so we get the correct height
			this.$previewEl.css({
				width: $grid.width(),
				left: -this.$item.position().left + 'px'
			});

			heightPreview = ogExpander.outerHeight(true) + marginExpanded;
			itemHeight = heightPreview + this.$item.data('height');

			this.height = heightPreview;
			this.itemHeight = itemHeight;

			this.$previewEl.css({
				height: this.height
			});
		},
		setHeights: function () {
			var self = this,
				onEndFn = function () {
					if (transEndEventName) {
						self.$item.off(transEndEventName);
					}
					self.$item.addClass('og-expanded');
				};

			this.calcSize();

			this.$item.css('margin-bottom', this.height).on(transEndEventName, onEndFn);

			if (!transEndEventName) {
				onEndFn.call();
			}
		},
		positionPreview: function () {
			// scroll page
			// preview height + item height fits in window´s height
			var position = this.$item.data('offsetTop'),
				previewOffsetT = this.$previewEl.offset().top - scrollExtra,
				scrollVal;

			if (window.matchMedia('(max-width: 767px)').matches) {
				scrollVal = previewOffsetT;
			} else {
				scrollVal = position;
			}

			$body.animate(
				{
					scrollTop: scrollVal
				},
				settings.speed
			);
		},
		setTransition: function () {
			this.$previewEl.css('transition', 'height ' + settings.speed + 'ms ' + settings.easing);
			this.$item.css(
				'transition',
				'margin-bottom ' + settings.speed + 'ms ' + settings.easing
			);
		},
		getEl: function () {
			return this.$previewEl;
		}
	};

	return {
		init: init,
		addItems: addItems,
		data: this.data,
		settings: this.settings,
		pages: this.pages
	};
};
