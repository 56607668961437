/* global $ */
import * as utils from '../utils';
import initDisqus from '../disqus/init';

export default class BlogEntry {
    constructor(options) {
        this._cache = {};
        this._component = options.component;
        this.id = options.id;

        this.disqusShortname = options.site.disqus_shortname;
        this.hasDisqus = this.disqusShortname && options.settings.hasDisqus;
        this.pageUrl = options.page.url;
        this.pageId = options.page.id;
        this.init();
    }

    init = () => {
        this._setupCache();
        this._addEvents();
        this._addDisqus();
    };

    /**
     * Caches re-used elements.
     */
    _setupCache = () => {
        this._cache.body = document.querySelector('body');
        this._cache.component = document.getElementById(this.id);
        this._cache.title = this._cache.component.querySelectorAll('.blog-entry-title');
        this._cache.image = this._cache.component.querySelectorAll('.blog-entry-image');
        this._cache.socialShareButtons = this._cache.component.querySelectorAll('.blog-entry-social-icon');
        this._cache.socialShareContainer = this._cache.component.querySelectorAll('.blog-entry-social');
    };

    /**
     * Handles a click to a specific social sharing icon.
     */
    _onSocialLinkClick = (e) => {
        const link = e.currentTarget;

        if (link.classList.contains('facebook')) {
            utils.shareOnFacebook(window.location.href);
        } else if (link.classList.contains('twitter')) {
            utils.shareOnTwitter(window.location.href);
        } else if (link.classList.contains('google')) {
            utils.shareOnGoogle(window.location.href);
        }
        else if (link.classList.contains('mail')) {
            utils.shareOnMail(window.location.href, $(this._cache.title).text());
        }
    };


    /*
     * Adds event handlers.
     */
    _addEvents = () => {
        if (this._cache.socialShareContainer) {
            // Set social sharing link destinations
            Array.from(this._cache.socialShareButtons).forEach((button) => {
                button.addEventListener('click', this._onSocialLinkClick);
            });
        }

    };

    _addDisqus = () => {
        if(!this.hasDisqus) {
            return;
        }
        initDisqus(this.pageUrl, this.pageId, this.disqusShortname);
    }

}
