/* global $ */
import moment from 'moment';
import './../libs/simple-pagination/jquery.simplePagination';

export function isSameDay(date1, date2){
    return moment(date1, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD') ===  moment(date2, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD');
}

export function getEventColor()  {
    //eslint-disable-next-line no-bitwise
    var bgRandomColor = '000000'.replace(/0/g,function(){return (~~(Math.random() * 16)).toString(16);});

    var returnedColor = bgRandomColor === 'FFFFFF' ? '000000' : bgRandomColor;
    return returnedColor;
}

export function paginateEvents(calendarBox) {
    var items = $(calendarBox).find('.event-wrapper');
    var numItems = items.length;
    var perPage = 2;

    items.slice(perPage).hide();
    $(calendarBox).find('.pagination').show();

    $(calendarBox).find('.pagination').pagination({
        items: numItems,
        itemsOnPage: perPage,
        displayedPages: 3,
        edges: 1,
        ellipsePageSet: false,
        onPageClick: function(pageNumber) {
            var showFrom = perPage * (pageNumber - 1);
            var showTo = showFrom + perPage;

            items.hide().slice(showFrom, showTo).show();
        }
    });
}
export function setEventColors(componentId, date) {
    var highlightedDateSelector = `.highlight_${componentId}_${date} .ui-state-default`;
    var eventBulletSelector = `.event_${componentId}_${date} .bullet`;
    var bgColor = getEventColor();
    $(`style#events-${componentId}`).append(
        `${highlightedDateSelector} {
            background-color: #${bgColor} !important;
            color: #FFFFFF  !important;
            font-weight: 600  !important;
        }
        ${eventBulletSelector} {
            background-color: #${bgColor} !important;
        }
    `);
}

export function resetEventColors(componentId) {
    $(`style#events-${componentId}`).text('');
}
