import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VideoList from '../../app/bento-components/video-list/video-list';
import { parseVideo, getPageCount } from '../../app/bento-components/video-list/video-utils';
import { PAGINATION_TYPES } from '../../app/bento-components/video-list/metadata';
import VideoCTA from './video-cta';
import { getVideos } from './ajax';

const htmlTag = document.querySelectorAll('html')[0];

class VideoListHOCFE extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        columnSize: PropTypes.number.isRequired,
        linkWidget: PropTypes.node,
        data: PropTypes.object.isRequired,
        API: PropTypes.object.isRequired,
        settings: PropTypes.shape({
            selectedShows: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired
                })
            ),
            itemsPerPage: PropTypes.number.isRequired,
            displayVideoTitle: PropTypes.bool.isRequired,
            displayShowTitle: PropTypes.bool.isRequired,
            displayVideoDescription: PropTypes.bool.isRequired,
            displayVideoMeta: PropTypes.bool.isRequired,
            videoType: PropTypes.string.isRequired,
            paginationType: PropTypes.string.isRequired,
            order: PropTypes.shape({
                value: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            }),
            player: PropTypes.shape({
                autoplay: PropTypes.bool.isRequired,
                topbar: PropTypes.bool.isRequired
            }),
            selectedRegions: PropTypes.array
        }),
        callsign: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            pageCount: 0,
            currentVideo: {},
            overlayVideo: false,
            showVideo: false,
            currentPage: 1,
            isLoadMoreEnabled: false
        };

        this.init = this.init.bind(this);
        this.videoClick = this.videoClick.bind(this);
        this.videoClose = this.videoClose.bind(this);
        this.onResize = this.onResize.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.showLoading = this.showLoading.bind(this);
    }

    componentDidMount() {
        this.onResize();
    }

    componentDidMount() {
        const {
            settings: { selectedShows, selectedRegions },
            settings,
            API
        } = this.props;
        const componentSettings = {
            ...settings
        };

        if (selectedRegions)
            componentSettings.selectedRegions = selectedRegions.map(({ value }) => value).join(',');

        if (selectedShows.length) {
            getVideos(API, componentSettings, this.showLoading).done(this.init);
        }
        window.addEventListener('resize', this.onResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        const isSmallBreakpoint = window.matchMedia('(max-width: 767px)').matches;
        const { showVideo } = this.state;
        this.setState({
            overlayVideo: isSmallBreakpoint
        });

        if (!isSmallBreakpoint) {
            htmlTag.classList.remove('modal-open');
        } else {
            if (showVideo) {
                htmlTag.classList.add('modal-open');
            }
        }
    }

    init(response) {
        const videos = response.data.map((video) => parseVideo(video));
        this.setState((state, props) => {
            return {
                videos: videos,
                pageCount: getPageCount(response),
                currentVideo: videos[0],
                isLoadMoreEnabled: props.settings.paginationType === PAGINATION_TYPES.LOAD_MORE
            };
        });
    }

    showLoading = (reset = false) => {
        const {
            settings: { itemsPerPage, paginationType }
        } = this.props;
        const { videos } = this.state;
        let isLoadMoreEnabled = true;
        let newVideos;

        if (paginationType === PAGINATION_TYPES.LOAD_MORE && !reset) {
            newVideos = [...videos, ...Array(itemsPerPage).fill({})];
            isLoadMoreEnabled = false;
        } else {
            newVideos = Array(itemsPerPage).fill({});
        }

        this.setState({
            videos: newVideos,
            isLoadMoreEnabled
        });
    };

    videoClick(e, video) {
        e.preventDefault();
        const { overlayVideo } = this.state;
        this.setState({
            currentVideo: video,
            showVideo: true
        });
        if (overlayVideo) {
            htmlTag.classList.add('modal-open');
        }
    }

    videoClose(e) {
        this.setState({
            showVideo: false
        });
        htmlTag.classList.remove('modal-open');
    }

    setCurrentPage(pageNumber) {
        this.setState((state, props) => {
            const validPage = Math.min(Math.max(1, pageNumber), state.pageCount);
            this.onPageChanged({ selected: validPage });
            return {
                currentPage: validPage
            };
        });
    }

    onPageChanged(page) {
        const {
            API,
            settings,
            settings: { selectedRegions }
        } = this.props;
        const { pageCount, currentPage, videos } = this.state;
        const validPage = Math.min(Math.max(1, page.selected), pageCount);
        let isLoadMoreEnabled = false;
        const componentSettings = {
            ...settings
        };

        if (selectedRegions)
            componentSettings.selectedRegions = selectedRegions.map(({ value }) => value).join(',');

        if (currentPage !== validPage) {
            getVideos(
                API,
                {
                    ...componentSettings,
                    page: validPage
                },
                this.showLoading
            ).done((response) => {
                let newVideos = [...videos];
                if (componentSettings.paginationType === PAGINATION_TYPES.LOAD_MORE) {
                    newVideos = [...videos, ...response.data.map((video) => parseVideo(video))];
                    isLoadMoreEnabled = true;
                }
                if (componentSettings.paginationType === PAGINATION_TYPES.PAGINATION) {
                    newVideos = [...response.data.map((video) => parseVideo(video))];
                }

                this.setState({
                    videos: newVideos,
                    pageCount: getPageCount(response),
                    isLoadMoreEnabled
                });
            });
        }
    }

    render() {
        const {
            videos,
            pageCount,
            currentVideo,
            showVideo,
            overlayVideo,
            currentPage,
            isLoadMoreEnabled
        } = this.state;
        const { settings, data, id, columnSize, callsign, site } = this.props;
        const VideoListTitle = () => (
            <h1 className="video-list-title">{data.title || data.titlePlaceholder}</h1>
        );

        return (
            <div className="bento-component">
                <VideoList
                    {...this.props}
                    columnSize={columnSize}
                    videos={videos}
                    settings={settings}
                    data={data}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    setCurrentPage={this.setCurrentPage}
                    componentId={id}
                    CallToAction={VideoCTA}
                    currentVideo={currentVideo}
                    videoClick={this.videoClick}
                    videoClose={this.videoClose}
                    VideoListTitle={VideoListTitle}
                    showVideo={showVideo}
                    playerClass={overlayVideo ? 'overlay' : ''}
                    isLoadMoreEnabled={isLoadMoreEnabled}
                    callsign={callsign}
										currentSite={ {isPillButtonAvailable: site.is_pill_button_available}}
                />
            </div>
        );
    }
}

export default VideoListHOCFE;
