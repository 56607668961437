export function shareOnFacebook(url) {
    // sharer.php returns error for protocol relative URLs so we parse and
    // construct the absolute URL
    const { protocol, hostname, pathname, search, hash } = parseURI(url);
	const sharedPage = `${protocol}//${hostname}${pathname}${encodeURIComponent(search)}${hash}`;
    const mediaPrefix = 'https://www.facebook.com/sharer/sharer.php?u=';
    const windowName = 'Facebook';
    const fullLink = `${mediaPrefix}${sharedPage}`;
    window.open(fullLink, windowName, 'height=300, width=550, resizable=1');
}

export function shareOnTwitter(url) {
    const mediaPrefix = 'https://twitter.com/intent/tweet?text=';
    const windowName = 'Twitter';
    const fullLink = mediaPrefix + url;
    window.open(fullLink, windowName, 'height=300, width=550, resizable=1');
}

export function shareOnMail(url, subject = '') {
    const fullLink = `mailto:recipient@example.com?body=${url}&subject=${subject.trim()}`;
    const windowName = 'Email';
    window.open(fullLink, windowName, 'height=300, width=550, resizable=1');
}

export function rstrip(string = '', char = '') {
    if (string.charAt(string.length - 1) === char) {
        return string.slice(0, -1);
    }

    return string;
}

export function parseURI(uri) {
    const parser = document.createElement('a');
    parser.href = uri;

    return {
        protocol: parser.protocol,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        hash: parser.hash,
        host: parser.host
    };
}

export const colorLuminance = (hex, lum) => {
    // More info: https://www.sitepoint.com/javascript-generate-lighter-darker-color/
    // ColorLuminance("#69c", 0);      returns "#6699cc"
    // ColorLuminance("6699CC", 0.2);  "#7ab8f5" - 20% lighter
    // ColorLuminance("69C", -0.5);    "#334d66" - 50% darker
    // ColorLuminance("000", 1);       "#000000" - true black cannot be made lighter!

    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
	var rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
};
