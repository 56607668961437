export default class Menu {
    constructor(options) {
        this._cache = {};
        this._component = options.component;
        this.id = options.id;
        this.init();
    }

    init = () => {
        // see more info about this polyfill here:
        // https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    let matches = (this.document || this.ownerDocument).querySelectorAll(s);
                    let i = matches.length - 1;
                    while (i >= 0 && matches.item(i) !== this) {
                        i--;
                    }
                    return i > -1;
                };
        }
        document.body.removeEventListener('mouseup', this._closeAllSubmenus);
        document.body.addEventListener('mouseup', this._closeAllSubmenus);
        window.addEventListener('resize', this._configureDynamicStyle);
        this._setupCache();
        this._addEvents();
        this._configureDynamicStyle();
    };

    /**
     * Caches re-used elements.
     */
    _setupCache = () => {
        this._cache.body = document.querySelector('body');
        // we are explicitly expecting just *one* menu
        this._cache.menuTrigger = this._component.querySelector('.menu__trigger');
        this._cache.menuItems = this._component.querySelectorAll('.menu-item');
        this._cache.submenuTriggers = this._component.querySelectorAll('.submenu__trigger');
        this._cache.search = this._component.querySelector('.station-header-search');
        this._cache.stackedMenu = this._component.querySelector('.nav-stacked');
        this._cache.menuCaret = this._component.querySelector('.menu-item-caret');
        this._cache.menuList = this._component.querySelector('header .menu--navbar-nav .menu__ul');
    };

    /**
     * set spacer height based on station header height
     * set mega menu position based on menu item
     * set regular menu height when in header & mobile & open (makes it scrollable, if needed)
     * close all open menus if in desktop
     */
    _configureDynamicStyle = () => {
        let navbar = document.getElementById(this.id);
        let spacer = this._component.querySelector('.header-fixed-spacer');
        if (spacer) {
            spacer.style.height = navbar.offsetHeight + 'px';
        }
        let menuItemsContainer = navbar.querySelector('.menu-container');
        let menuItems = menuItemsContainer
            ? menuItemsContainer.querySelectorAll('.menu__li')
            : null;
        const openSubmenuItem = menuItemsContainer?.querySelector('.submenu-is-open');
        const menuListDesktop = this._cache.menuList?.getBoundingClientRect().width > 440;

        if (menuItems) {
            for (let i = 0; i < menuItems.length; i++) {
                let submenu = menuItems[i].querySelector('.submenu.mega-menu');
                if (submenu) {
                    let menuItemX = menuItems[i].offsetLeft - 1;
                    submenu.style.left = '-' + menuItemX + 'px';
                    submenu.style.width = menuItemsContainer.offsetWidth + 'px';
                }
            }
        }
        if (this._cache.body.classList.contains('nav-is-open') && this._cache.menuList) {
            this._setOpenNavHeight();
        }

        if (menuListDesktop) {
            this._hideNav()
            this._cache.menuList.removeAttribute('style');
            if (openSubmenuItem) {
                this._hideSubmenu(openSubmenuItem);
            }
        }
    };

    _setOpenNavHeight = () => {
        let distanceFromTop = this._cache.menuList.getBoundingClientRect().top;
        let heightStyleString = `height: calc(100vh - ${distanceFromTop}px - env(safe-area-inset-bottom))`;
        this._cache.menuList.setAttribute('style', heightStyleString);
    };
    /**
     * Shows the left side nav.
     */
    _showNav = () => {
        this._cache.body.classList.add('nav-is-open');
        if (this._cache.menuList) {
            this._setOpenNavHeight();
        }
    };

    /**
     * Hides the left side nav.
     */
    _hideNav = () => {
        this._cache.body.classList.remove('nav-is-open');
    };

    /**
     * Shows the submenu
     */
    _showSubmenu = (target) => {
        target.classList.add('submenu-is-open');
    };

    /**
     * Hides the submenu
     */
    _hideSubmenu = (target) => {
        target.classList.remove('submenu-is-open');
    };

    /**
     * Handles a click to the main nav trigger (left side hamburger menu button).
     */
    _onMenuTriggerClick = (e) => {
        e.preventDefault();
        if (this._cache.body.classList.contains('nav-is-open')) {
            this._hideNav();
        } else {
            this._showNav();
        }
    };

    /**
     * Handles a click to the sub menu trigger
     */
    _onSubmenuTriggerClick = (e) => {
        e.preventDefault();
        const btn = e.currentTarget;

        if (btn.parentNode.classList.contains('submenu-is-open')) {
            this._hideSubmenu(btn.parentNode);
        } else {
            this._showSubmenu(btn.parentNode);
        }
    };

    _getParentByClass = (elem, selector) => {
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) {
                return elem;
            }
        }
        return null;
    };

    _closeAllSubmenus = () => {
        document.querySelectorAll('.open-submenu').forEach((elem) => {
            elem.classList.remove('open-submenu');
        });
    };

    _toggleSubMenu = (e, menuItem) => {
        e.stopPropagation();
        const curMenuLink =
            e.target.classList.contains('has-children') ||
            e.target.classList.contains('fa-angle-down') ||
            e.target.classList.contains('fa-angle-up');
        const itemCaret =
            menuItem.querySelector('.fa-angle-down') || menuItem.querySelector('.fa-angle-up');

        document.querySelectorAll('.open-submenu-on-click').forEach((elem) => {
            if (menuItem !== elem) {
                const elemCaret = elem.querySelector('.fa-angle-up');

                elem.classList.remove('open-submenu-on-click');
                elemCaret.classList.remove('fa-angle-up');
                elemCaret.classList.add('fa-angle-down');
            }
        });

        if (menuItem && curMenuLink) {
            menuItem.classList.toggle('open-submenu-on-click');

            if (itemCaret.classList.contains('fa-angle-up')) {
                itemCaret.classList.remove('fa-angle-up');
                itemCaret.classList.add('fa-angle-down');

                return;
            }

            itemCaret.classList.remove('fa-angle-down');
            itemCaret.classList.add('fa-angle-up');
        }
    };

    /**
     * Adds event handlers.
     */
    _addEvents = () => {
        const that = this;
        const eventType = MouseEvent.prototype.hasOwnProperty('relatedTarget')
            ? 'focusout'
            : 'blur';

        if (this._cache.menuCaret) {
            // Open/close left side user menu on click
            this._cache.menuCaret.addEventListener('click', (e) => e.preventDefault());
        }

        if (this._cache.menuTrigger) {
            // Open/close left side user menu on click
            this._cache.menuTrigger.addEventListener('click', this._onMenuTriggerClick);
        }
        if (this._cache.submenuTriggers) {
            // Open/close sub menus
            this._cache.submenuTriggers.forEach((trigger) => {
                trigger.addEventListener('click', this._onSubmenuTriggerClick);
            });
        }
        if (this._cache.search) {
            this._cache.search.addEventListener('focus', function () {
                that._closeAllSubmenus();
                that._cache.search.classList.add('open');
            });
            const dropdownElements = this._cache.search.querySelectorAll('input, button');
            const lastElementIndex = dropdownElements.length - 1;
            const lastElement = dropdownElements[lastElementIndex];
            if (lastElement) {
                this._cache.search.addEventListener(eventType, function (e) {
                    //if target is a sibling, shift + tab was used, do not close dropdown
                    if (
                        e.relatedTarget &&
                        that._getParentByClass(e.relatedTarget, '.station-header-search-form') ===
                            that._getParentByClass(lastElement, '.station-header-search-form')
                    ) {
                        return;
                    }
                    that._cache.search.classList.remove('open');
                });
            }
        }

        if (this._cache.menuItems) {
            let menuItems = this._cache.menuItems;

            // Open submenu on focus (tab key)
            menuItems.forEach((menuItem) => {
                if (this._cache.stackedMenu && menuItem.classList.contains('has-children'))
                    menuItem.parentElement.addEventListener('click', (e) =>
                        this._toggleSubMenu(e, menuItem.parentElement)
                    );

                //only add focus handlers for elements with submenu
                if (menuItem.nextElementSibling) {
                    menuItem.addEventListener('focus', function () {
                        that._closeAllSubmenus();
                        this.parentElement.classList.add('open-submenu');
                    });
                    menuItem.addEventListener(eventType, function (e) {
                        //if target is a submenu item, do not close
                        if (
                            e.relatedTarget &&
                            that._getParentByClass(e.relatedTarget, '.open-submenu') ===
                                this.parentElement
                        ) {
                            return;
                        }
                        this.parentElement.classList.remove('open-submenu');
                    });

                    let subMenu = menuItem.nextElementSibling;
                    while (subMenu && !subMenu.matches('.submenu')) {
                        subMenu = subMenu.nextElementSibling;
                    }

                    const subMenuLinks = subMenu.querySelectorAll('a');
                    const lastLinkIndex = subMenuLinks.length - 1;
                    const lastLink = subMenuLinks[lastLinkIndex];
                    if (lastLink) {
                        lastLink.addEventListener(eventType, function (e) {
                            //if target is a sibling, shift + tab was used, do not close submenu
                            if (
                                e.relatedTarget &&
                                that._getParentByClass(e.relatedTarget, '.submenu') ===
                                    that._getParentByClass(lastLink, '.submenu')
                            ) {
                                return;
                            }
                            that._closeAllSubmenus();
                        });
                    }
                }
            });
        }
    };
}
