import { pageSection } from 'constants';
import JSONPath from 'JSONPath';

export function getPageSection(state) {
    // page: compatibility for old data
    return state.section || state.page;
}

export function isSectionGlobal(sectionName) {
    return sectionName !== pageSection.CONTENT;
}

export function getSectionContent(state, jsonPath) {
    const content = JSONPath({ json: state, path: jsonPath });
    return content[0] || {};
}
