import icon from './media/icon.svg';
import disabledIcon from './media/disabledIcon.svg';
import { sectionType, DEFAULT_LINK, ALL_SITE_TYPES, DOCS_BASE_URL } from 'constants';
import THEME from './theme.jsx';

const NAME = 'video-list'; /* component internal name */
const VERBOSE_NAME = 'video list'; /* component displayed name */

export const BENTOMATIC_FIELDS = {
    title: 'data.title',
    CTAText: 'text'
};

export const DEFAULT_CTA = {
    text: '',
    textPlaceholder: 'Call To Action',
    link: { ...DEFAULT_LINK }
};

export const VIDEO_TYPES = {
    ALL: '',
    CLIP: 'clip',
    PREVIEW: 'preview',
    EPISODE: 'full_length',
    SPECIAL: 'special'
};

export const ITEMS_PER_PAGE = {
    min: 4,
    max: 24
};

export const PAGINATION_TYPES = {
    LOAD_MORE: 'LOAD_MORE',
    PAGINATION: 'PAGINATION'
};

export const ORDER = [
    {
        value: '-encored_on',
        id: 'ENCORE_DATE',
        title: 'Date'
    },
    {
        value: 'title_sortable',
        id: 'VIDEO_TITLE',
        title: 'Video Title'
    }
];

/* component visible data */
const DATA = {
    cta: {},
    title: '',
    titlePlaceholder: 'Video List Title'
};

/* component settings */
const SETTINGS = {
    selectedShows: [],
    itemsPerPage: 12,
    displayVideoListTitle: true,
    displayVideoTitle: true,
    displayShowTitle: true,
    displayVideoDescription: true,
    displayVideoMeta: true,
    displayCTA: false,
    includePassportVideos: true,
    videoType: VIDEO_TYPES.ALL,
    paginationType: PAGINATION_TYPES.PAGINATION,
    order: ORDER[0],
    player: {
        autoplay: false,
        topbar: false
    },
    selectedRegions: [{ value: 'US', label: 'US' }]
};

const RESTRICTION = {
    allowedSections: new Set([sectionType.CONTENT]),
    allowedColumns: new Set([12, 8]),
    allowedSiteTypes: ALL_SITE_TYPES
};

const METADATA = {
    name: NAME,
    verboseName: VERBOSE_NAME,
    icon,
    disabledIcon,
    data: DATA,
    settings: SETTINGS,
    restriction: RESTRICTION,
    theme: THEME
};

export const DOCUMENTATION_LINK = DOCS_BASE_URL + 'B3/4751549/Video+List';

export default METADATA;
