import queryString from 'query-string';
import JSONPath from 'JSONPath';
import moment from 'moment';
import uuid from 'uuid';
import 'moment-duration-format';

/**
 * Return the parent show from a Media Manager video asset
 * @param  {Object} videoJson - The response JSON for a Media Manager video asset
 * @return {JSON}             - The portion of the Media Manager asset JSON
 *                              response representing the parent show
 */
export function getParentShow(videoJson) {
    return (
        videoJson &&
        (videoJson.attributes.show ||
            JSONPath({
                json: videoJson,
                path: '$..parent_tree..show'
            })[0])
    );
}

/**
 * Return the Media Manager asset thumbnail image
 * @param  {Object} videoJson           - The response JSON for a Media Manager
 *                                        video asset
 * @param  {String} preferredProfile    - The preferred image profile.
 *                                        Default is asset-mezzanine-16x9
 * @return {String}                     - The image url

 */
export function getThumbnail(videoJson, preferredProfile = 'asset-mezzanine-16x9') {
    if (!videoJson) {
        return;
    }

    const preferredImage = JSONPath({
        json: videoJson,
        path: `$..images[?(@.profile=="${preferredProfile}")].image`
    })[0];

    return (
        preferredImage ||
        // get the first image if preferred profile was not found
        JSONPath({
            json: videoJson,
            path: '$..images[0].image'
        })[0]
    );
}

/**
 * Return the Media Manager asset premiere date
 * @param  {Object} videoJson - The response JSON for a Media Manager video asset
 * @return {String}           - The formated `premiered_on` date
 */
export function getDate(videoJson) {
    return videoJson && moment(videoJson.attributes.encored_on).format('YYYY/MM/DD');
}

/**
 * Return the Media Manager asset duration
 * @param  {Object} videoJson - The response JSON for a Media Manager video asset
 * @return {String}           - The formated `duration date`
 */
export function getDuration(videoJson) {
    return (
        videoJson && moment.duration(videoJson.attributes.duration, 'seconds').format('m[m] ss[s]')
    );
}

export function getPageCount(videoJson) {
    /* eslint-disable camelcase */
    const { per_page, count } = videoJson.meta.pagination;
    return Math.ceil(count / per_page);
    /* eslint-enable camelcase */
}

function isPassportVideo(videoJson) {
    if (!videoJson) {
        return false;
    }
    let { attributes: { availabilities } = {} } = videoJson;
    /* eslint-disable camelcase */
    let {
        all_members: { start: allStart, end: allEnd } = {},
        public: { start: publicStart, end: publicEnd } = {},
        station_members: { start: stationStart, end: stationEnd } = {}
    } = availabilities;
    /* eslint-enable camelcase */
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    publicStart = !publicStart ? new Date(0) : new Date(publicStart);
    publicEnd = !publicEnd ? tomorrow : new Date(publicEnd);
    allStart = !allStart ? new Date(0) : new Date(allStart);
    allEnd = !allEnd ? tomorrow : new Date(allEnd);
    stationStart = !stationStart ? new Date(0) : new Date(stationStart);
    stationEnd = !stationEnd ? tomorrow : new Date(stationEnd);

    if (publicStart <= today && today < publicEnd) {
        return false;
    }
    if ((allStart <= today && today < allEnd) || (stationStart <= today && today < stationEnd)) {
        return true;
    }

    return false;
}

/**
 * Returns the parsed Media Manager video asset into the VideoItem.jsx component
 * expected format leaving only the required fields
 * @param  {Object} videoJson - The response JSON for a Media Manager video asset
 * @return {Object}           - The formated props required by the VideoItem
 */
export function parseVideo(videoJson) {
    return (
        videoJson && {
            id: videoJson.id,
            showTitle: getParentShow(videoJson).attributes.title || ' ',
            videoTitle: videoJson.attributes.title || ' ',
            duration: getDuration(videoJson) || ' ',
            playerIframe: configPlayer(videoJson.attributes.player_code, null, {
                id: `partner-player-${uuid.v4()}`,
                allow: 'encrypted-media'
            }),
            thumbnail: getThumbnail(videoJson),
            date: getDate(videoJson) || ' ',
            rating: videoJson.attributes.content_rating || ' ',
            shortDescription: videoJson.attributes.description_short || ' ',
            longDescription: videoJson.attributes.description_long || ' ',
            type: videoJson.attributes.object_type,
            isPassportVideo: isPassportVideo(videoJson)
        }
    );
}

export function configPlayer(playerHTMLString, config = {}, attributes = {}) {
    if (!playerHTMLString) {
        return;
    }

    const parser = document.createElement('div');
    parser.innerHTML = playerHTMLString;
    const domElem = parser.firstChild;
    const [path, query] = domElem.src.split('?');
    const currentCfg = queryString.parse(query);
    let newConfig = {
        ...currentCfg,
        ...config
    };

    domElem.src = `${path}?${queryString.stringify(newConfig)}`;
    domElem.referrerPolicy = 'no-referrer-when-downgrade';
    domElem.loading = 'lazy';
    Object.keys(attributes).forEach((attrName) => {
        domElem[attrName] = attributes[attrName];
    });

    return domElem.outerHTML;
}

export function settingsToQueryString(settings = {}) {
    const { selectedShows, itemsPerPage, videoType, order, page, includePassportVideos } = settings;
    const params = {
        'show-id': selectedShows.length && selectedShows.map((show) => show.id),
        'page-size': itemsPerPage,
        sort: order.value,
        page: page || 1
    };
    switch (videoType) {
        case 'special':
            params['parent-type'] = 'special';
            params.type = 'full_length';
            break;
        case 'full_length':
            params['parent-type'] = 'episode';
            params.type = 'full_length';
            break;
        default:
            params.type = videoType;
    }

    if (includePassportVideos) {
        params.available = 'station_members';
    } else {
        params.available = 'public';
    }
    const encodedParams = encodeURI(queryString.stringify(params));

    return `?${encodedParams}`;
}

export function mapAssetType(type) {
    if (type === 'full_length') {
        return 'episode';
    }

    return type;
}
