import { apiStatus } from 'constants';
import uuid from 'uuid';

export function storeSavedContent(content) {
    // deep clone to avoid modifing other objects
    const contentClone = JSON.parse(JSON.stringify(content));
    return (
        contentClone &&
        contentClone.components && {
            savedContent: contentClone,
            savedComponents: Object.keys(contentClone.components),
            content: {
                ...content,
                operation: {},
                hasComponentDataChanges: false,
                hasComponentSettingsChanges: false
            }
        }
    );
}

export function reduceGetData(state, action) {
    switch (action.status) {
        case apiStatus.IN_PROGRESS:
        case apiStatus.FAILED:
            return {
                ...state,
                status: action.status,
                error: action.error
            };
        case apiStatus.OK:
            return {
                status: action.status,
                error: null,
                data: action.data
            };
        default:
            return { ...state };
    }
}

export function shouldIgnoreDataChange(dataKeys) {
    return dataKeys.length === 1 && dataKeys[0] === 'currentItemIndex';
}

export function shouldIgnoreSettingsChange(settingsKeys) {
    return settingsKeys.length === 1 && settingsKeys[0] === 'cls';
}

export function regenerateLayoutIds(content, newLayoutId) {
    const uuidRegexp = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/g;
    const textVarRegexp = /<(\/?|!?)textvar+.*?>/g;
    let newContent = { ...content };

    for (let compKey in content.components) {
        if (content.components[compKey].data) {
            newContent.components[compKey].data = JSON.parse(
                JSON.stringify(content.components[compKey].data)
                    .replace(uuidRegexp, (match) => {
                        //make all uuids unique
                        let newId = uuid.v4();
                        if (content.components[compKey].settings) {
                            newContent.components[compKey].settings = JSON.parse(
                                JSON.stringify(content.components[compKey].settings).replace(
                                    match,
                                    newId
                                )
                            );
                        }
                        return newId;
                    })
                    .replace(textVarRegexp, '')
            ); //cleanup text variables
        }
    }

    for (let colKey in content.columns) {
        newContent.columns[colKey].components = content.columns[colKey].components.map(
            (componentId) => {
                let newCompId = uuid.v4();
                newContent.components[newCompId] = content.components[componentId];
                newContent.components[newCompId].id = newCompId;
                delete newContent.components[componentId];
                return newCompId;
            }
        );
        let newColKey = uuid.v4();
        newContent.columns[newColKey] = content.columns[colKey];
        newContent.columns[newColKey].id = newColKey;
        delete newContent.columns[colKey];
    }
    if (content.layout) {
        newContent.layout.id = newLayoutId;
        if (content.layout.columns && Array.isArray(content.layout.columns)) {
            newContent.layout.columns.splice(
                0,
                newContent.layout.columns.length,
                ...Object.keys(newContent.columns)
            );
        }
    }

    return newContent;
}

export function regenerateComponentIds(content, newComponentId) {
    const uuidRegexp = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/g;
    const textVarRegexp = /<(\/?|!?)textvar+.*?>/g;
    let newContent = { ...content };

    if (!content || !content.component) {
        return newContent;
    }

    if (content.component.data) {
        newContent.component.data = JSON.parse(
            JSON.stringify(content.component.data)
                .replace(uuidRegexp, (match) => {
                    //make all uuids unique
                    let newId = uuid.v4();
                    if (content.component.settings) {
                        newContent.component.settings = JSON.parse(
                            JSON.stringify(content.component.settings).replace(match, newId)
                        );
                    }
                    return newId;
                })
                .replace(textVarRegexp, '')
        ); //cleanup text variables
    }
    newContent.component.id = newComponentId;
    return newContent;
}
