/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    adaptCategories,
    adaptEntries,
    adaptEntry
} from '../../app/bento-components/blog-river/ajax';
import BlogRiverCommon from '../../app/bento-components/blog-river/blog-river-common';
import social from '../../app/bento-components/blog-river/social';
import {
    PAGINATION_TYPES,
    ALL_CATEGORIES,
    ALL_TAGS
} from '../../app/bento-components/blog-river/metadata';
import styles from './river.scss';

const INITIAL_STATE = {
    entries: [],
    currentPage: 1,
    pageCount: 0,
    filters: {
        categories: [],
        tags: []
    },
    categories: [],
    tags: []
};

export class River extends Component {
    static propTypes = {
        settings: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        API: PropTypes.object.isRequired,
        columnSize: PropTypes.number.isRequired,
        basePath: PropTypes.string.isRequired,
        baseHost: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isPreview: PropTypes.bool.isRequired,
        site: PropTypes.object.isRequired,
        featureToggles: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            featuredEntries: [],
            tags: (props.settings && props.settings.tags) || [],
            currentPage:
                (props.location.query && props.location.query.page) || INITIAL_STATE.currentPage
        };

        this.setCurrentPage = this.setCurrentPage.bind(this);
        this.setEntries = this.setEntries.bind(this);
        this.setCategories = this.setCategories.bind(this);
        this.setFeaturedEntries = this.setFeaturedEntries.bind(this);
        this.fetchCategories = this.fetchCategories.bind(this);
        this.fetchEntries = this.fetchEntries.bind(this);
        this.fetchEntry = this.fetchEntry.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
    }

    componentDidMount() {
        const {
            settings: { itemsPerPage, featuredEntries, tags = [] }
        } = this.props;
        const { currentPage } = this.state;

        this.setCategories().done((entryResponse) => {
            const adaptedCategories = adaptCategories(entryResponse);
            const routeCategories = this.getRouteFilters('categories', this.props);
            const activeCategories = adaptedCategories.filter((c) => {
                return (
                    routeCategories.findIndex(
                        (cat) => parseInt(cat.id, 10) === parseInt(c.id, 10)
                    ) > -1
                );
            });

            let filters = activeCategories.length
                ? { categories: activeCategories, tags }
                : { categories: [], tags };

            /* No tags fetching because tags cannot be entirely removed by users, as opposed to categories */
            this.setState(
                {
                    filters
                },
                () => {
                    this.setFeaturedEntries(featuredEntries, adaptedCategories);
                    this.setEntries(currentPage, itemsPerPage, {
                        categories: filters.categories,
                        tags: filters.tags
                    });
                }
            );
        });
    }

    getRouteFilters = (filterName, props) => {
        let {
            location: { query = {} }
        } = props;

        if (query[filterName]) {
            return query[filterName].split(',').map((id) => ({ id: parseInt(id, 10) }));
        }

        return [];
    };

    setCurrentPage(page) {
        const {
            history,
            basePath,
            location,
            settings: { paginationType, itemsPerPage }
        } = this.props;
        const { pageCount, filters } = this.state;
        const validPage = Math.min(Math.max(page, 0), pageCount);
        this.setState({ currentPage: validPage });
        if (paginationType === PAGINATION_TYPES.PAGINATION) {
            history.push({
                pathname: basePath,
                query: {
                    ...location.query,
                    page: validPage
                }
            });
        }
        this.setEntries(validPage, itemsPerPage, filters, true);
    }

    toggleFilter(filterObject, filterName) {
        const {
            settings: { itemsPerPage },
            history,
            basePath,
            location: { query }
        } = this.props;
        const { categories: categoriesSetting, tags: tagsSetting } = this.state;
        const activeFilters = this.state.filters[filterName];
        let newActiveFilters = [...activeFilters].filter((f) => typeof f.id === 'number');
        const filterIndex = activeFilters.findIndex((f) => f.id === filterObject.id);
        const isfilterActive = filterIndex > -1;

        if (isfilterActive) {
            newActiveFilters.splice(filterIndex, 1);
        } else {
            newActiveFilters = [...newActiveFilters, filterObject];
        }
        const newFilters = {
            ...this.state.filters,
            [filterName]: newActiveFilters
        };

        this.setState({
            filters: newFilters,
            entries: [],
            pageCount: INITIAL_STATE.pageCount,
            currentPage: INITIAL_STATE.currentPage
        });

        history.push({
            pathname: basePath,
            query: {
                ...query,
                [filterName]: newActiveFilters.map((f) => f.id).join(',')
            }
        });

        this.setEntries(
            INITIAL_STATE.currentPage,
            itemsPerPage,
            {
                ...newFilters,
                categories: newFilters.categories.length
                    ? newFilters.categories
                    : categoriesSetting,
                tags: newFilters.tags.length ? newFilters.tags : tagsSetting
            },
            true
        );
    }

    setEntries(currentPage, itemsPerPage, filters, reset) {
        const {
            settings: { order },
            site: { id: siteId }
        } = this.props;

        return this.fetchEntries(filters, order, currentPage, itemsPerPage, '', siteId).done(
            (response) => {
                this.setState((state, props) => {
                    const entriesCategories = filters.categories.length
                        ? filters.categories
                        : state.categories;
                    const newEntries = adaptEntries(response, entriesCategories);
                    const { entries: currentEntries } = reset ? INITIAL_STATE : state;
                    const entries =
                        // eslint-disable-next-line react/prop-types
                        props.paginationType === PAGINATION_TYPES.LOAD_MORE
                            ? [...currentEntries, ...newEntries]
                            : newEntries;
                    return {
                        entries: entries,
                        pageCount: response.meta.pagination.pages
                    };
                });
            }
        );
    }

    setCategories() {
        const {
            site: { id: siteId }
        } = this.props;

        return this.fetchCategories(siteId).done((response) => {
            this.setState((state, props) => {
                const { categories: propsCategories = [ALL_CATEGORIES] } = props.settings;
                const hasAllCategories =
                    propsCategories.findIndex((categ) => categ.id === ALL_CATEGORIES.id) > -1;
                let categories = adaptCategories(response);
                let categoryIds = propsCategories.map((c) => c.id);

                if (!hasAllCategories) {
                    categories = categories.filter((c) => categoryIds.includes(c.id));
                }
                return {
                    categories: categories
                };
            });
        });
    }

    setFeaturedEntries(propsFeaturedEntries, categories) {
        const {
            site: { id: siteId }
        } = this.props;
        propsFeaturedEntries.map((propsFeaturedEntry) => {
            return this.fetchEntry(propsFeaturedEntry.id, siteId).done((entryResponse) => {
                let featuredEntry = adaptEntry(entryResponse, categories);
                if (featuredEntry) {
                    // eslint-disable-next-line no-unused-vars
                    this.setState((state, props) => ({
                        featuredEntries: [...state.featuredEntries, featuredEntry]
                    }));
                }
            });
        });
    }

    /* eslint-disable camelcase */
    fetchCategories(siteId = '') {
        const {
            API: { BLOG_CATEGORIES_URL },
            isPreview
        } = this.props;

        return $.ajax({
            method: 'GET',
            url: BLOG_CATEGORIES_URL,
            data: { site_ids: siteId, preview: isPreview },
            type: 'json',
            cache: false
        }).fail((xhr, status, err) => {
            console.error('Failed to get blog categories', xhr, status, err);
        });
    }

    // eslint-disable-next-line no-unused-vars
    fetchEntries(filters, ordering, currentPage = 1, itemsPerPage = 15, title = '', siteId = '') {
        const {
            API: { BLOG_ENTRIES_URL },
            isPreview
        } = this.props;
        const { categories: categoriesSetting = [], tags: tagsSetting = [] } = this.state;
        let filterParams = {};
        let categories = filters.categories.length ? filters.categories : categoriesSetting;
        let tags = filters.tags.length ? filters.tags : tagsSetting;

        const hasAllCategories =
            categories.findIndex((categ) => categ.id === ALL_CATEGORIES.id) > -1;
        const hasAllTags = tags.findIndex((tag) => tag.id === ALL_TAGS.id) > -1;
        const categoryIds = hasAllCategories ? '' : categories.map((c) => c.id).join(',');
        let tagIds = hasAllTags ? '' : tags.map((t) => t.id).join(',');

        /* eslint-disable camelcase */
        if (categoryIds) {
            filterParams.category_ids = categoryIds;
        }

        if (tagIds) {
            filterParams.tag_ids = tagIds;
        }
        /* eslint-enable camelcase */

        return $.ajax({
            method: 'GET',
            url: BLOG_ENTRIES_URL,
            data: {
                ...filterParams,
                page_size: itemsPerPage,
                page: currentPage,
                ordering: ordering,
                preview: isPreview,
                site_ids: siteId
            },
            type: 'json',
            cache: false
        }).fail((xhr, status, err) => {
            console.error('Failed to get blog entries', xhr, status, err);
        });
    }
    /* eslint-enable camelcase */

    fetchEntry(id) {
        const {
            API: { BLOG_ENTRY_URL },
            site: { id: siteId }
        } = this.props;

        return $.ajax({
            method: 'GET',
            url: BLOG_ENTRY_URL.replace('{blogEntryId}', id).replace('{site_ids}', siteId),
            type: 'json',
            cache: false
        }).fail((xhr, status, err) => {
            console.error('Failed to get blog entry', xhr, status, err);
        });
    }

    render() {
        const {
            entries,
            categories,
            tags,
            currentPage,
            pageCount,
            filters,
            featuredEntries
        } = this.state;
        const {
            settings: { itemsPerPage },
            data,
            columnSize,
            baseHost,
            featureToggles,
						site
        } = this.props;
        const zeroBasedPage = currentPage - 1;
        const riverTitle = <h1 className="river-title">{data.title || data.titlePlaceholder}</h1>;
        const entriesPerRow = columnSize <= 8 ? 2 : 3;
        const domainAwareTargetLink = this.props.site.domain === baseHost ? '_self' : '_blank';

        return (
            <div className={styles.river}>
                <BlogRiverCommon
                    isPreview
                    isBlogRiverTitleEmpty={!data.title}
                    title={riverTitle}
                    categories={categories}
                    tags={tags}
                    entries={entries}
                    zeroBasedPage={zeroBasedPage}
                    currentPage={currentPage}
                    pageCount={pageCount}
                    data={data}
                    settings={this.props.settings}
                    setCurrentPage={this.setCurrentPage}
                    toggleFilter={this.toggleFilter}
                    filters={filters}
                    social={social}
                    entriesPerRow={entriesPerRow}
                    setEntries={(page) =>
                        this.setEntries(page, itemsPerPage, {
                            categories: filters.categories,
                            tags: filters.tags
                        })
                    }
                    featuredEntries={featuredEntries}
                    linkTarget={domainAwareTargetLink}
                    featureToggles={featureToggles}
										currentSite={ {isPillButtonAvailable: site.is_pill_button_available}}
                />
            </div>
        );
    }
}

export default withRouter(River);
