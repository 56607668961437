import defaultImage from './media/default-image.svg';
import ICON from './media/icon.png';
import { ALL_SITE_TYPES, DEFAULT_LINK, sectionType } from 'constants';

const NAME = 'blog-entry';
const VERBOSE_NAME = 'blog post';

export const BENTOMATIC_FIELDS = {
    image: 'data.image'
};

const DATA = {
    entryId: null,
    titlePlaceholder: 'Post Title',
    author: {},
    publicationDate: '',
    modificationDate: '',
    customPublicationDate: '',
    datePlaceholder: 'Post Date',
    image: {
        url: defaultImage
    },
    caption: '',
    captionPlaceholder: 'Caption',
    credit: '',
    creditPlaceholder: 'Credit',
    imageAltText: '',
    html: '',
    htmlPlaceholder: 'Post Body',
    shortDescription: '',
    shortDescriptionPlaceholder: 'Preview Short Description',
    code: ''
};

const SETTINGS = {
    showImage: true,
    showCaption: true,
    showCredit: true,
    showDate: true,
    showAuthor: true,
    showSocialIcons: true,
    showContent: true,
    showEmbedded: false,
    category: '',
    tags: [],
    hasDisqus: true,
    showNextAndPrev: false,
    imageLink: DEFAULT_LINK
};

const RESTRICTION = {
    allowedSections: new Set([sectionType.CONTENT]),
    allowedColumns: new Set([12, 8]),
    allowedSiteTypes: ALL_SITE_TYPES
};

const METADATA = {
    name: NAME,
    verboseName: VERBOSE_NAME,
    icon: ICON,
    data: DATA,
    settings: SETTINGS,
    restriction: RESTRICTION
};

export default METADATA;
