// At least, this should be the provider of BE endpoints, am most root for API utils

export const apiEndpoints = {
    PAGES: '/bento-cms/api/pages/',
    PAGE: '/bento-cms/api/pages/{pageId}/',
    PAGE_DETAIL: '/bento-cms/api/pages/{pageId}/additional/',
    HOLIDAYS: '/bento-cms/api/holidays/',
    COLOR_PALETTES: '/bento-cms/api/color_palettes/',
    COLOR_PALETTE: '/bento-cms/api/color_palettes/{paletteId}/',
    TEMPLATE_SITES: '/bento-cms/template-sites/',
    COMPONENT_PREVIEW: '/bento-cms/component-preview/{contentVersionId}/',
    CONTENT_VERSIONS: '/bento-cms/api/content_versions/',
    SITES: '/bento-cms/api/sites/',
    SITES_IGNORE_PERMISSIONS: '/bento-cms/api/sites/?ignorePermissions=true',
    ROLES: '/bento-cms/api/roles/',
    GROUPS: '/bento-cms/api/groups/',
    USERS: '/bento-cms/api/users/',
    USER: '/bento-cms/api/bento_users/{user_id}/',
    SITE: '/bento-cms/api/sites/{siteId}/',
    SITES_EXTENDED: '/bento-cms/api/sites_extended/',
    SITE_EXTENDED: '/bento-cms/api/sites_extended/{siteId}/',
    STATION_MANAGER_SEARCH:
        'https://station.services.pbs.org/api/public/v1/stations/?call_sign={searchTerm}',
    FILER_FILE_LOCATION: '/bento-api/filer-file-location/{filerFileId}/',
    FILER_FILE_LOCATION_DOWNLOAD: '/bento-api/filer-file-download/{filerFileId}/',
    FILES: '/bento-cms/api/filer/folder-listing/?site_ids={site_id}',
    FILER_FOLDER: '/bento-cms/api/filer/folder-listing/{folderId}/?site_ids={site_id}',
    COPY_FILES: '/bento-cms/api/filer/folder-listing/copy/',
    MOVE_FILES: '/bento-cms/api/filer/folder-listing/move/',
    DELETE_FILES: '/bento-cms/api/filer/folder-listing/delete/',
    FILE_DETAILS: '/bento-cms/api/filer/files/{fileId}/?site_ids={site_id}/',
    FOLDER_DETAILS: '/bento-cms/api/filer/folders/{folderId}/?site_ids={site_id}/',
    FOLDERS: '/bento-cms/api/filer/folders/',
    FILER_TAGS: '/bento-cms/api/filer/tags/?site_ids={site_id}/',
    FILER_TRASH_FILES: '/bento-cms/api/filer/trash/?site={site_id}',
    FILER_TRASH_FOLDER: '/bento-cms/api/filer/trash/{folderId}/?site={site_id}',
    FILER_TRASH_RESTORE: '/bento-cms/api/filer/trash/restore/',
    FILER_SEARCH: '/bento-cms/api/filer/folder-listing/search/',
    FILER_SEARCH_TRASH: '/bento-cms/api/filer/trash/search/',
    UNZIP_FILES: '/bento-cms/api/filer/folder-listing/unzip/',
    YOUTUBE: 'https://www.googleapis.com/youtube/v3/videos?part=snippet&id={videoId}&key={key}',
    BLOG_CATEGORIES: '/bento-cms/api/blogs/categories/?site_ids={site_ids}',
    BLOG_CATEGORY: '/bento-cms/api/blogs/categories/{categoryId}/?site_ids={site_ids}',
    BLOG_ENTRIES:
        '/bento-cms/api/blogs/entries/?category_ids={category_ids}&tag_ids={tag_ids}&page_size={page_size}&page={page}&title={title}&ordering={ordering}&site_ids={site_ids}',
    BLOG_ENTRY: '/bento-cms/api/blogs/entries/{blogEntryId}/?site_ids={site_ids}',
    BLOG_TAGS:
        '/bento-cms/api/blogs/tags/?name={tagName}&page_size={page_size}&page={page}&site_ids={site_ids}',
    BLOG_AUTHORS:
        '/bento-cms/api/blogs/authors/?name={authorName}&page_size={page_size}&page={page}&user_id={userId}&site_ids={site_ids}',
    MEDIA_MANAGER_ASSETS: '/bento-api/media-manager/{siteId}/assets/',
    MEDIA_MANAGER_SHOWS: '/bento-api/media-manager/{siteId}/shows/',
    MEDIA_MANAGER_SEARCH_SHOWS: '/bento-api/media-manager/{siteId}/shows/search/',
    BENTOMATIC_SOURCES: '/bento-cms/api/bentomatics/?search={bentomaticTitle}&type=consumer',
    BENTOMATIC_SOURCE: '/bento-cms/api/bentomatics/{bentomaticId}/?type=consumer',
    BENTOMATICS: '/bento-cms/api/bentomatics/',
    FEATURE_TOGGLES: '/bento-cms/api/switches/',
    REDIRECTS: '/bento-cms/api/redirects/bulk/',
    REDIRECTS_PAGE: '/bento-cms/api/redirects/',
    REDIRECTS_IMPORT: '/bento-cms/api/redirects/import-redirects/',
    REDIRECTS_EXPORT: '/bento-cms/api/redirects/export-redirects/',
    REDIRECT: '/bento-cms/api/redirects/{redirectId}/',
    RECENT_PAGES: '/bento-cms/api/pages/recent_pages/',
    DASHBOARD_SEARCH: '/bento-cms/api/search/?site={siteId}&adapter=vx&keywords={keywords}',
    STATS: '/bento-cms/api/stats/',
    PAGE_PRESETS: '/bento-cms/api/pagepresets/',
    OPERATIONS: '/bento-cms/api/operations/',
    BENTOMATIC_ANALYTICS: '/bento-cms/api/usage/bentomatics/{bentomaticId}/',
    BENTOMATIC_ANALYTICS_CSV: '/bento-cms/api/usage/bentomatics/{bentomaticId}/csv/',
    BENTOMATIC_IFRAME: '//{domain}/bento-api/bentomatics-iframe/{bentomaticId}/?iframe=1',
    USER_UNREAD_NOTIFICATIONS: '/bento-api/notifications/',
    MARK_NOTIFICATION_READ: '/bento-api/mark-notifications-read/',
    PREVIEW_UNSAVED_CHANGES_URL: '/bento-cms/preview/'
};

export const browsersPaths = {
    MEDIA_MANAGER: '/merlin_plugin/merlin_browse/{paramName}/{siteId}/{onlyVideo}',
    FILER:
        '/admin/filer/folder/?_to_field=file_ptr&current_site={siteId}&_popup=0&file_type={fileType}',
    FILER_FOLDER:
        '/admin/filer/folder/{folderId}/list/?_to_field=file_ptr&current_site={siteId}&_popup=0&file_type={fileType}',
    FILES_PAGE: '/admin/filer/folder/',
    FILES_FOLDER_PAGE: '/admin/filer/folder/{folderId}/list/'
};

export const externalApi = {
    JAWS: ((window.PBSBentoAdmin || {}).API || {}).jaws || 'https://jaws.pbs.org',
    STATIC_DIST: (window.PBSBentoAdmin || {}).STATIC_DIST
};

export const JAWSEndpoints = {
    GET_WIDGETS: externalApi.JAWS + '/getwidgets/{apiKey}/'
};
